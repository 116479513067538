import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type {
  AsyncStatus,
  AsyncState,
  WithAsyncState,
  ServiceError,
} from '@models';

export type AsyncPendingStatus = Extract<
  AsyncStatus,
  'loading' | 'creating' | 'updating' | 'deleting'
>;

export const createInitialAsyncState = (): AsyncState => ({
  status: 'initial',
  error: undefined,
});

export const withAsyncState = <T>(state: T): WithAsyncState<T> => ({
  ...createInitialAsyncState(),
  ...state,
});

export interface AsyncStateAdapter {
  setInitial<S extends AsyncState>(state: S): S;
  setPending<S extends AsyncState>(state: S, status?: AsyncPendingStatus): S;
  setFulfilled<S extends AsyncState>(state: S): S;
  setRejected<S extends AsyncState>(state: S, error?: ServiceError): S;
}

const createAsyncStateAdapter = (): AsyncStateAdapter => ({
  setInitial: state => Object.assign(state, createInitialAsyncState()),
  setPending: (state, status = 'loading') => {
    state.status = status;
    state.error = undefined;
    return state;
  },
  setFulfilled: state => {
    state.status = 'ready';
    state.error = undefined;
    return state;
  },
  setRejected: (state, error) => {
    state.status = 'error';
    state.error = error;
    return state;
  },
});

export interface AsyncSelectors<State> {
  selectStatus: (state: State) => AsyncStatus;
  selectError: (state: State) => ServiceError | undefined;
  selectIsInitial: (state: State) => boolean;
  selectIsLoading: (state: State) => boolean;
  selectIsCreating: (state: State) => boolean;
  selectIsUpdating: (state: State) => boolean;
  selectIsDeleting: (state: State) => boolean;
  selectIsIdle: (state: State) => boolean;
  selectIsError: (state: State) => boolean;
  selectIsPending: (state: State) => boolean;
}

const createAsyncSelectorsFactory = () => {
  function getSelectors(): AsyncSelectors<AsyncState>;
  function getSelectors<State>(
    selectState: (state: State) => AsyncState,
  ): AsyncSelectors<State>;
  function getSelectors<State>(selectState?: (state: State) => AsyncState) {
    const selectStatus = (state: AsyncState) => state.status;
    const selectError = (state: AsyncState) => state.error;

    const selectIsInitial = (state: AsyncState) =>
      selectStatus(state) === 'initial';

    const selectIsLoading = (state: AsyncState) =>
      selectStatus(state) === 'loading';

    const selectIsCreating = (state: AsyncState) =>
      selectStatus(state) === 'creating';

    const selectIsUpdating = (state: AsyncState) =>
      selectStatus(state) === 'updating';

    const selectIsDeleting = (state: AsyncState) =>
      selectStatus(state) === 'deleting';

    const selectIsIdle = (state: AsyncState) => selectStatus(state) === 'ready';

    const selectIsError = (state: AsyncState) =>
      selectStatus(state) === 'error';

    const selectIsPending = (state: AsyncState) =>
      ['loading', 'creating', 'updating', 'deleting'].includes(
        selectStatus(state),
      );

    if (selectState) {
      return {
        selectStatus: createDraftSafeSelector(selectState, selectStatus),
        selectError: createDraftSafeSelector(selectState, selectError),
        selectIsInitial: createDraftSafeSelector(selectState, selectIsInitial),
        selectIsLoading: createDraftSafeSelector(selectState, selectIsLoading),
        selectIsCreating: createDraftSafeSelector(
          selectState,
          selectIsCreating,
        ),
        selectIsUpdating: createDraftSafeSelector(
          selectState,
          selectIsUpdating,
        ),
        selectIsDeleting: createDraftSafeSelector(
          selectState,
          selectIsDeleting,
        ),
        selectIsIdle: createDraftSafeSelector(selectState, selectIsIdle),
        selectIsError: createDraftSafeSelector(selectState, selectIsError),
        selectIsPending: createDraftSafeSelector(selectState, selectIsPending),
      } satisfies AsyncSelectors<State>;
    }

    return {
      selectStatus,
      selectError,
      selectIsInitial,
      selectIsLoading,
      selectIsCreating,
      selectIsUpdating,
      selectIsDeleting,
      selectIsIdle,
      selectIsError,
      selectIsPending,
    } satisfies AsyncSelectors<AsyncState>;
  }

  return { getSelectors };
};

export interface AsyncAdapter extends AsyncStateAdapter {
  getInitialState(): AsyncState;
  getInitialState<S>(state: S): AsyncState & S;
  getSelectors(): AsyncSelectors<AsyncState>;
  getSelectors<S>(selectState: (state: S) => AsyncState): AsyncSelectors<S>;
}

export const createAsyncAdapter = (): AsyncAdapter => {
  const getInitialState = (state: unknown = {}) => withAsyncState(state);

  const { getSelectors } = createAsyncSelectorsFactory();

  const stateAdapter = createAsyncStateAdapter();

  return {
    ...stateAdapter,
    getInitialState,
    getSelectors,
  };
};

export default createAsyncAdapter;
