import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Button, Form, Grid, Icon, List, Popup } from 'semantic-ui-react';

import {
  SET_PASSWORD_UPDATE_ERROR,
  SET_PASSWORD_UPDATE_SUCCESS,
} from '@redux/actionTypes';
import { setCaretToEndOfInput } from '@utils/DOMUtils';
import raiseToast from '@shared/components/Toast';

// eslint-disable-next-line postcss-modules/no-unused-class
import s from './ChangePassword.scss';

class ChangePassword extends PureComponent {
  constructor() {
    super();
    this.state = {
      currPass: '',
      newPass: '',
      confirm: '',
      type: 'password',
    };
  }

  /**
   * [componentDidMount description]
   * @return {[type]} [description]
   */
  componentDidMount() {
    if (document.getElementById('curr-password')) {
      setCaretToEndOfInput(document.getElementById('curr-password'));
    }
    return this;
  }

  /**
   * [changePass description]
   * @return {[type]} [description]
   */
  changePass = () => {
    if (this.checkPass()) {
      const { currPass, newPass, type } = this.state;
      this.props
        .updatePassword({
          type,
          oldpass: currPass,
          newpass: newPass,
        })
        .then(resp => {
          if (resp && resp.type === SET_PASSWORD_UPDATE_SUCCESS) {
            raiseToast({
              toastId: resp.type,
              title: 'Success!',
              message: (
                <span>Your password has been successfully updated.</span>
              ),
              level: 'success',
              autoClose: 8000,
              dismissAll: true,
            });
            this.resetForm();
          } else if (resp && resp.type === SET_PASSWORD_UPDATE_ERROR) {
            const { errorMsg } = this.props.system;
            const { message } = errorMsg;
            raiseToast({
              toastId: resp.type,
              title: 'Error',
              message: <span>{message}</span>,
              level: 'error',
              autoClose: 8000,
              dismissAll: true,
            });
          }
        });
    } else {
      raiseToast({
        toastId: SET_PASSWORD_UPDATE_ERROR,
        title: 'Validation Failed',
        message: (
          <span>
            Please ensure that your new password has no whitespaces, at least 6
            characters, and is different than your current one.
          </span>
        ),
        level: 'error',
        autoClose: 8000,
        dismissAll: true,
      });
    }
    return this;
  };

  /**
   * [checkPass description]
   * @return {[type]} [description]
   */
  checkPass = () => {
    const { currPass, newPass, confirm } = this.state;
    const hasValidCurr =
      currPass && typeof currPass === 'string' && currPass.trim().length;
    const hasValidNew =
      newPass && typeof newPass === 'string' && newPass.trim().length > 5;
    const hasNoSpaces = newPass.match(/^\S*$/);
    const isDiff = newPass !== currPass;
    return (
      hasValidCurr &&
      hasValidNew &&
      newPass === confirm &&
      hasNoSpaces &&
      isDiff
    );
  };

  /**
   * [resetForm description]
   * @return {[type]} s[description]
   */
  resetForm = () => {
    this.setState({
      currPass: '',
      newPass: '',
      confirm: '',
      type: 'password',
    });
    return this;
  };

  /**
   * [render description]
   * @return {[type]} s[description]
   */
  render() {
    const { system } = this.props;
    const { isUpdatingPassword } = system;
    const { currPass, newPass, confirm } = this.state;

    return (
      <div className={s.root}>
        <Grid className={s.grid} verticalAlign="middle" centered>
          <Grid.Row>
            <Grid.Column>
              <Form className="changePass animate__animated animate__fadeIn">
                <Form.Field>
                  <Form.Input
                    id="curr-password"
                    label="Current Password"
                    placeholder="Enter your current password"
                    required
                    name="currPass"
                    autoComplete="off"
                    spellCheck={false}
                    size="small"
                    type="password"
                    value={currPass}
                    onChange={(e, { value }) => {
                      this.setState({
                        currPass: value.trim().length ? value.trim() : '',
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    id="new-password"
                    label="New Password"
                    placeholder="Must be different and at least 6 alphanumeric characters"
                    required
                    name="newPass"
                    autoComplete="off"
                    spellCheck={false}
                    size="small"
                    type="password"
                    value={newPass}
                    icon={
                      newPass.length ? (
                        <Popup
                          position="right center"
                          trigger={
                            newPass &&
                            newPass.match(/^\S*$/) &&
                            newPass.length > 5 &&
                            newPass !== currPass ? (
                              <Icon
                                name="check"
                                color="green"
                                link
                                className="active animate__animated animate__fadeIn"
                                role="img"
                              />
                            ) : (
                              <Icon
                                name="exclamation circle"
                                color="red"
                                link
                                className="active animate__animated animate__fadeIn"
                                role="img"
                              />
                            )
                          }
                          content={
                            <List style={{ padding: '0.5rem' }}>
                              <List.Item
                                icon={
                                  newPass.match(/^\S*$/) ? 'check' : 'close'
                                }
                                content="No whitespaces"
                                className={s.ruleItem}
                                style={{
                                  color: newPass.match(/^\S*$/)
                                    ? '#21ba45'
                                    : '#db2828',
                                }}
                              />
                              <List.Item
                                icon={newPass.length > 5 ? 'check' : 'close'}
                                content="At least 6 characters long"
                                className={s.ruleItem}
                                style={{
                                  color:
                                    newPass.length > 5 ? '#21ba45' : '#db2828',
                                }}
                              />
                              <List.Item
                                icon={newPass !== currPass ? 'check' : 'close'}
                                content="Must be different to current"
                                className={s.ruleItem}
                                style={{
                                  color:
                                    newPass !== currPass
                                      ? '#21ba45'
                                      : '#db2828',
                                }}
                              />
                            </List>
                          }
                        />
                      ) : null
                    }
                    onChange={(e, { value }) => {
                      this.setState({
                        newPass: value.trim().length ? value.trim() : '',
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    id="confirm-password"
                    label="Confirm New Password"
                    placeholder="Re-enter your new password"
                    required
                    name="confirm"
                    autoComplete="off"
                    spellCheck={false}
                    size="small"
                    type="password"
                    value={confirm}
                    icon={
                      confirm.length ? (
                        <Popup
                          position="right center"
                          trigger={
                            confirm === newPass ? (
                              <Icon
                                name="check"
                                color="green"
                                link
                                className="active animate__animated animate__fadeIn"
                                role="img"
                              />
                            ) : (
                              <Icon
                                name="exclamation circle"
                                color="red"
                                link
                                className="active animate__animated animate__fadeIn"
                                role="img"
                              />
                            )
                          }
                          content={
                            <List style={{ padding: '0.5rem' }}>
                              <List.Item
                                icon={confirm === newPass ? 'check' : 'close'}
                                className={s.ruleItem}
                                content="Matches new password"
                                style={{
                                  color:
                                    confirm === newPass ? '#21ba45' : '#db2828',
                                }}
                              />
                            </List>
                          }
                        />
                      ) : null
                    }
                    onChange={(e, { value }) => {
                      this.setState({
                        confirm: value.trim().length ? value.trim() : '',
                      });
                    }}
                  />
                </Form.Field>
                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '.28571429rem',
                  }}
                >
                  <Button
                    icon
                    basic
                    fluid
                    color="blue"
                    labelPosition="right"
                    size="small"
                    type="submit"
                    loading={isUpdatingPassword}
                    disabled={isUpdatingPassword}
                    onClick={this.changePass}
                  >
                    <Icon name="save" />
                    Save New Password
                  </Button>
                </div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export const propTypes = {
  system: PropTypes.shape({
    errorMsg: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
    isUpdatingPassword: PropTypes.bool,
    passwordUpdateError: PropTypes.bool,
  }).isRequired,

  updatePassword: PropTypes.func.isRequired,
};

ChangePassword.propTypes = propTypes;

export default withStyles(s)(ChangePassword);
