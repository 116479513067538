/* eslint-disable import/prefer-default-export */
import type { AuthAccountState } from '@redux/reducers/reducer-auth';

// Obtain the account context in order to qualify any `history` changes
// required, either within the view it is called in or by any
// subcomponents within said view
export const getAccountContextForHistory = (
  context: AuthAccountState['context'],
): string =>
  context?.accountname ? `/${encodeURIComponent(context.accountname)}` : '';
