import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import type { ReactNode } from 'react';
import { useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Loader,
  Menu,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react';

import history from '@/history';
import { getUserPermsSync } from '@/rbac';
import type { Permissions } from '@models';
import { SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS } from '@redux/actionTypes';
import { logout } from '@redux/actions/action-auth';
import { updateAccountContext } from '@redux/actions/action-system';
import { setTopnavViewProps } from '@redux/actions/action-topnav';
import {
  setWhatsNewDialog,
  setWhatsNewShowCtrl,
} from '@redux/actions/action-whatsnew';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { showModal } from '@redux/modals.slice';
import { useSystemStatus } from '@redux/system/health.slice';
import { setViewState } from '@redux/views.slice';
import arePermsGoodForRoute from '@routes/arePermsGoodForRoute';
import type { RouteMetadata, RouteMetadataNavbar } from '@routes/routeMetadata';
import routeMetadata from '@routes/routeMetadata';
import ConditionalWrap from '@shared/components/ConditionalWrap';
import DarkModeToggle from '@shared/components/DarkModeToggle';
import Link from '@shared/components/Link';
import StatusIcon, {
  healthStatusVariants,
} from '@shared/components/StatusIcon';
import raiseToast from '@shared/components/Toast';
import ApiKeysModal from '@components/System/AccountsView/AccountView/ApiKeysModal';

import ChangePassword from './ChangePassword';
import RegistryList from './RegistryList';
import WhatsNew from './WhatsNew';
// eslint-disable-next-line
import type { WhatsNewData } from './WhatsNew/WhatsNew';
import s from './Topnav.scss';

const USER_TIMEZONE = moment.tz.guess();

const NAV_ICONS = {
  dashboard: 'dashboard',
  applications: 'th',
  artifacts: 'tags',
  policy: 'suitcase',
  reports: 'list alternate outline',
  events: 'bullhorn',
  system: 'setting',
  inventory: s.kubeIcon,
};

// Factory that turns each entitlement into a list of <List.Item> elements
const makeEntitlementList = (
  arr: { name?: string; label?: string; description?: string; uri?: string }[],
) =>
  arr.map(item => {
    let el: ReactNode = null;
    if (item && typeof item.name === 'string' && item.name.trim().length) {
      const name = item.name.trim().toLowerCase();

      const label =
        typeof item.label === 'string' && item.label.trim().length
          ? item.label.trim()
          : name;

      const uri =
        typeof item.uri === 'string' && item.uri.trim().length
          ? item.uri.trim()
          : false;

      const desc =
        typeof item.description === 'string' && item.description.trim().length
          ? item.description.trim()
          : false;

      el = (
        <span className={classNames(s[`${name}Logo`], s.entitlementLogo)}>
          <span>{label}</span>
        </span>
      );
      if (uri) {
        el = (
          <a href={uri} target="_blank" rel="noopener noreferrer">
            {el}
          </a>
        );
      }
      el = (
        <List.Item
          className={s.listItem}
          key={!desc ? `entitlement_${name}` : null}
        >
          <Icon
            color="teal"
            size={!desc ? 'tiny' : undefined}
            name={desc ? 'info circle' : 'circle'}
          />
          <List.Content className={s.listItemContent}>{el}</List.Content>
        </List.Item>
      );

      if (desc) {
        el = (
          <Popup
            trigger={el}
            className={classNames(
              s.listItemPopup,
              'animate__animated animate__fadeIn',
            )}
            key={`entitlement_${name}`}
            position="left center"
            hideOnScroll
            hoverable
            content={
              <div>
                <Header as="h4">About {label}</Header>
                <p>{desc}</p>
              </div>
            }
          />
        );
      }
    }
    return el;
  });

interface TopnavProps {
  permissions: Permissions | false;
  customLinks: CustomLinks | false;
  active: string;
  isLicenseGood: IsLicenseGood;
  whatsNew: WhatsNewData | false;
  uiVersion: string;
}

const Topnav = (props: TopnavProps) => {
  const {
    permissions,
    customLinks,
    active,
    isLicenseGood,
    whatsNew,
    uiVersion,
  } = props;

  const auth = useAppSelector(state => state.auth);

  const app = useAppSelector(state => state.app);
  const system = useAppSelector(state => state.system);
  const systemStatus = useSystemStatus();
  const isOnboardingEnabled = useAppSelector(
    state => state.views.gettingStarted?.isEnabled ?? false,
  );

  const dispatch = useAppDispatch();

  const setTopnavViewPropsWrapper = (viewProps: {
    contextitm?: boolean;
    linksitm?: boolean;
    registryitm?: boolean;
    accountbtn?: boolean;
  }) => {
    dispatch(setTopnavViewProps('topnav', viewProps));
  };

  const [ctxpin, setCtxpin] = useState(false);
  const [lnkpin, setLnkpin] = useState(false);

  /**
   * [description]
   * @return {[type]} [description]
   */
  const showAccountSwitch = () => {
    const { topnav } = app.topnav.viewProps;

    if (!auth.account) {
      return null;
    }

    const { accounts, context, accountname } = auth.account;
    const hasAccounts =
      accounts instanceof Array &&
      accounts.filter(
        item => item.state === 'enabled' || item.state === 'disabled',
      ).length > 1;

    let itemEl = context ? (
      <Dropdown.Item
        key="ctx"
        disabled={!hasAccounts}
        selected={topnav.contextitm}
        className={s.acctMenuItem}
        icon={<Icon name="shuffle" color="teal" />}
        onMouseEnter={() => {
          setCtxpin(true);
          setLnkpin(false);
          setTopnavViewPropsWrapper({
            contextitm: true,
            linksitm: false,
          });
        }}
        onMouseLeave={() => {
          setCtxpin(false);
          setLnkpin(false);
        }}
        onClick={() => {
          setTopnavViewPropsWrapper({
            contextitm: true,
            linksitm: false,
          });
        }}
        content="Switch Account Data Context"
      />
    ) : null;

    if (itemEl && hasAccounts) {
      itemEl = (
        <Popup
          trigger={itemEl}
          position="left center"
          onMouseLeave={() => {
            setCtxpin(false);
          }}
          open={
            system.isUpdatingAccountContext === true
              ? false
              : ctxpin || topnav.contextitm
          }
          hoverable
          basic
          className={classNames(
            s.acctPopup,
            s.ctxPopup,
            'animate__animated animate__fadeIn',
          )}
        >
          <List selection>
            {accounts
              .filter(
                item => item.state === 'enabled' || item.state === 'disabled',
              )
              .map(item => (
                <List.Item
                  key={`account_${item.name}`}
                  className={s.acctListItem}
                  onMouseDown={() => {
                    if (
                      item.name !== context?.accountname &&
                      item.state === 'enabled'
                    ) {
                      setCtxpin(false);
                      setLnkpin(false);
                      dispatch(updateAccountContext({ name: item.name })).then(
                        (resp: {
                          type: string;
                          resp?: {
                            data?: {
                              account?: { context?: { isEnabled: boolean } };
                            };
                          };
                        }) => {
                          if (
                            resp &&
                            resp.type === SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS
                          ) {
                            raiseToast({
                              toastId: `${item.name}:${resp.type}`,
                              message: (
                                <span>
                                  The account data context for the current
                                  session&nbsp;
                                  {item.name === accountname
                                    ? 'has been returned to account'
                                    : `has been switched to ${
                                        !resp?.resp?.data?.account?.context
                                          ?.isEnabled
                                          ? '(disabled)'
                                          : ''
                                      } account`}
                                  &nbsp;
                                  <strong>{item.name}</strong>.
                                </span>
                              ),
                              level: 'info',
                              icon:
                                item.name === accountname
                                  ? 'angle double left'
                                  : 'shuffle',
                              autoClose: 6000,
                            });
                          } else {
                            raiseToast({
                              toastId: `${item.name}:${resp.type}`,
                              message: (
                                <span>
                                  <strong>We&#39;re sorry&hellip;</strong>
                                  &nbsp;but due to a service error we were not
                                  able to make <strong>{item.name}</strong>
                                  &nbsp;the new data context.
                                </span>
                              ),
                              level: 'error',
                              autoClose: 8000,
                            });
                          }
                        },
                      );
                    } else {
                      setCtxpin(true);
                      setLnkpin(false);
                    }
                  }}
                >
                  <List.Icon
                    color="green"
                    name={
                      item.name === context?.accountname
                        ? 'check circle'
                        : 'circle outline'
                    }
                  />
                  <List.Icon
                    style={{
                      position: 'absolute',
                      color: '#fff',
                      zIndex: 0,
                    }}
                    name="circle"
                  />
                  <List.Content className={s.acctListItemContent}>
                    <Icon
                      color={item.state === 'enabled' ? 'teal' : 'grey'}
                      name={
                        item.type === 'admin' ? 'chess queen' : 'user circle'
                      }
                    />
                    {item.type !== 'admin' ? (
                      <List.Icon
                        style={{
                          position: 'absolute',
                          color: '#fff',
                          zIndex: 0,
                        }}
                        name="circle"
                      />
                    ) : null}
                    <span>
                      <span
                        className={classNames(
                          s.acctNameItem,
                          item.name === context?.accountname
                            ? s.currentCtx
                            : null,
                        )}
                      >
                        {item.name}
                      </span>
                      {item.state !== 'enabled' ? (
                        <span
                          className={classNames(
                            s.acctNameItem,
                            s.acctDisabledItem,
                          )}
                        >
                          Disabled
                        </span>
                      ) : null}
                      {item.name === accountname ? (
                        <span
                          className={classNames(
                            s.acctNameItem,
                            s.acctActualItem,
                          )}
                        >
                          Actual
                        </span>
                      ) : null}
                    </span>
                  </List.Content>
                </List.Item>
              ))}
          </List>
        </Popup>
      );
    }
    return itemEl;
  };

  /**
   * [description]
   * @return {[type]} [description]
   */
  const showCustomLinks = () => {
    const { topnav } = app.topnav.viewProps;

    let itemEl = customLinks ? (
      <Dropdown.Item
        // disabled={!hasAccounts}
        key="lnk"
        selected={topnav.linksitm}
        className={s.acctMenuItem}
        icon={<Icon name="external" color="teal" />}
        onMouseEnter={() => {
          setCtxpin(false);
          setLnkpin(true);
          setTopnavViewPropsWrapper({
            linksitm: true,
            contextitm: false,
          });
        }}
        onMouseLeave={() => {
          setCtxpin(false);
          setLnkpin(false);
        }}
        onClick={() => {
          setTopnavViewPropsWrapper({
            linksitm: true,
            contextitm: false,
          });
        }}
        content={
          <span className={s.acctMenuItemTitle}>{customLinks.title}</span>
        }
      />
    ) : null;

    if (itemEl) {
      itemEl = (
        <Popup
          trigger={itemEl}
          position="left center"
          onMouseLeave={() => {
            setCtxpin(false);
            setLnkpin(false);
          }}
          open={lnkpin || topnav.linksitm}
          hoverable
          basic
          className={classNames(
            s.acctPopup,
            s.lnkPopup,
            'animate__animated animate__fadeIn',
          )}
        >
          {customLinks && (
            <List selection>
              {customLinks.links.map(item => (
                <List.Item
                  key={`${item.title}_${Math.random()}`}
                  className={s.acctListItem}
                >
                  <List.Content
                    as="a"
                    rel="noopener noreferrer"
                    href={item.uri}
                    target="_blank"
                    className={s.acctListItemContent}
                  >
                    <Icon color="teal" name="external" />
                    <span>{item.title}</span>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          )}
        </Popup>
      );
    }
    return itemEl;
  };

  const showInfoModal = (trigger?: ReactNode) => {
    const {
      account,
      engineVersion,
      dbVersion,
      enterpriseCommitSha,
      imageBuildTimestamp,
    } = auth;

    const {
      id,
      type,
      user,
      email,
      expiration,
      isBeforeWarning,
      organization,
      entitlements,
    } = isLicenseGood;

    // In reality this guard should never be hit,
    if (!account) {
      return <></>;
    }

    const {
      username,
      authUsername,
      authType,
      userCreatedAt,
      accountname,
      sessionStart,
      isAdmin,
      rbac,
      context,
    } = account;

    const acctCtx =
      context && context.accountname ? context.accountname : accountname;

    const hasPermissionsForOnboarding =
      permissions &&
      permissions.createRegistry &&
      permissions.createImage &&
      permissions.getActions;

    return (
      <Modal
        role="dialog"
        id="about_client_modal"
        className="animate__animated animate__fadeInDownBig"
        size="small"
        closeIcon
        trigger={
          trigger || (
            <Dropdown.Item
              key="info"
              className={s.acctMenuItem}
              onMouseEnter={() => {
                setCtxpin(false);
                setTopnavViewPropsWrapper({
                  contextitm: false,
                });
              }}
              icon={<Icon name="user circle" color="teal" />}
              content="About Anchore Enterprise Client"
              data-test="about-client-modal-trigger"
            />
          )
        }
      >
        <Header
          style={{
            fontWeight: 100,
            backgroundColor: 'rgb(54, 130, 224)',
            border: 0,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span className={s.aboutLogo} />
          <span data-test="about-client-modal" className={s.aboutTitle}>
            Enterprise Client
          </span>
        </Header>
        <Modal.Content style={{ overflow: 'hidden' }}>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8} verticalAlign="middle">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className={classNames(
                          'animate__animated animate__bounceInDown',
                          s.sadRobot,
                        )}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="equal">
                    {whatsNew && !whatsNew.isWhatsNewCtrlShown && (
                      <Grid.Column textAlign="center">
                        <Button
                          size="small"
                          color="green"
                          icon
                          labelPosition="right"
                          onClick={() => {
                            dispatch(setWhatsNewDialog(true));
                            dispatch(setWhatsNewShowCtrl(true));
                            const aboutClientModal =
                              document.getElementById('about_client_modal');
                            if (
                              aboutClientModal &&
                              aboutClientModal.firstChild
                            ) {
                              (
                                aboutClientModal.firstChild as HTMLElement
                              ).click();
                            }
                          }}
                        >
                          What&#39;s New?
                          <Icon name="gift" />
                        </Button>
                      </Grid.Column>
                    )}
                    {!isOnboardingEnabled && hasPermissionsForOnboarding && (
                      <Grid.Column textAlign="center">
                        <Button
                          size="small"
                          color="grey"
                          icon
                          labelPosition="right"
                          onClick={() => {
                            // Restart the Onboarding steps
                            dispatch(
                              setViewState('gettingStarted', {
                                currentStep: 0,
                                completedSteps: 0,
                                isEnabled: true,
                              }),
                            );
                            // Close About modal
                            const aboutClientModal =
                              document.getElementById('about_client_modal');
                            if (
                              aboutClientModal &&
                              aboutClientModal.firstChild
                            ) {
                              (
                                aboutClientModal.firstChild as HTMLElement
                              ).click();
                            }
                            // Navigate to Dashboard
                            history?.push('/dashboard');
                          }}
                        >
                          Getting Started
                          <Icon name="map marker alternate" />
                        </Button>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column>
                      <Icon name="tv" color="teal" />
                      &nbsp;&nbsp;Enterprise Client Version:&nbsp;
                      <code>{uiVersion}</code>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column>
                      <Icon name="plug" color="teal" />
                      &nbsp;&nbsp;Enterprise Service Version:&nbsp;
                      {enterpriseCommitSha || imageBuildTimestamp ? (
                        <Popup
                          wide="very"
                          hoverable
                          trigger={<code>{engineVersion}</code>}
                          position="top center"
                          content={
                            <div data-test="service-version-popup">
                              <p>
                                Commit SHA:&nbsp;
                                <code>{enterpriseCommitSha || '---'}</code>
                              </p>
                              <p>
                                Image built:&nbsp;
                                {imageBuildTimestamp
                                  ? moment(imageBuildTimestamp)
                                      .tz(USER_TIMEZONE)
                                      .format(
                                        'ddd, DD MMM YYYY [at] HH:mm:ss z',
                                      )
                                  : '---'}
                              </p>
                            </div>
                          }
                        />
                      ) : (
                        <code>{engineVersion}</code>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column>
                      <Icon name="database" color="teal" />
                      &nbsp;&nbsp;Anchore DB Version:&nbsp;
                      <code>{dbVersion}</code>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column>
                      <Icon name="copyright outline" color="teal" />
                      &nbsp;&nbsp;
                      {moment().year()} Anchore Inc. All Rights Reserved
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Divider fitted horizontal className={s.divider}>
          <Icon circular inverted color="teal" name="user circle" />
        </Divider>
        <Modal.Content style={{ margin: 0, padding: '1rem' }}>
          <Grid columns={entitlements ? 2 : 1}>
            <Grid.Row verticalAlign="middle" divided>
              <Grid.Column width={entitlements ? 10 : 16}>
                <Grid columns={2}>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column width={6}>
                      <strong>User Created:</strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      {moment(userCreatedAt)
                        .tz(USER_TIMEZONE)
                        .format('MMMM Do YYYY')}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column width={6}>
                      <strong>Username:</strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <code>{authUsername}</code>
                      &nbsp;&nbsp;
                      {isAdmin ? (
                        <span>
                          <Icon name="chess queen" color="teal" />
                          <span>Administrator</span>
                        </span>
                      ) : (
                        <span>
                          <Icon name="user circle" color="teal" />
                          <span>Standard User</span>
                        </span>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column width={6}>
                      <strong>Member of Account:</strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <code>{accountname}</code>
                      {(() => {
                        let permsEl: ReactNode = null;
                        if (!isAdmin) {
                          const perms = rbac
                            .find(item => item.account === accountname)
                            ?.permissions.map(permItem => (
                              <List.Item as="li" key={permItem.action}>
                                <code>
                                  {permItem.action === '*'
                                    ? 'All Permissions (Full Control)'
                                    : permItem.action}
                                </code>
                              </List.Item>
                            ));
                          permsEl = (
                            <Popup
                              content={
                                <div>
                                  <div>
                                    Listed below are the permissions granted for
                                    use within account&nbsp;
                                    <code>
                                      <strong>{accountname}</strong>
                                    </code>
                                    :
                                  </div>
                                  <List as="ul">{perms}</List>
                                </div>
                              }
                              position="right center"
                              trigger={
                                <span className={s.viewPermsLink}>
                                  View Permissions
                                </span>
                              }
                            />
                          );
                        }
                        return permsEl;
                      })()}
                    </Grid.Column>
                  </Grid.Row>
                  {acctCtx !== accountname ? (
                    <Grid.Row className={s.aboutItem}>
                      <Grid.Column width={6}>
                        <strong>Current Account Context:</strong>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <code>{acctCtx}</code>
                        {(() => {
                          let permsEl: ReactNode = null;
                          if (!isAdmin) {
                            const perms = rbac
                              .find(item => item.account === acctCtx)
                              ?.permissions.map(permItem => (
                                <List.Item as="li" key={permItem.action}>
                                  <code>{permItem.action}</code>
                                </List.Item>
                              ));
                            permsEl = (
                              <Popup
                                content={
                                  <div>
                                    <div>
                                      Listed below are the permissions granted
                                      for use within account&nbsp;
                                      <code>
                                        <strong>{acctCtx}</strong>
                                      </code>
                                      :
                                    </div>
                                    <List as="ul">{perms}</List>
                                  </div>
                                }
                                position="right center"
                                trigger={
                                  <span className={s.viewPermsLink}>
                                    View Permissions
                                  </span>
                                }
                              />
                            );
                          }
                          return permsEl;
                        })()}
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                  {authUsername !== username && authType !== 'jwt' ? (
                    <Grid.Row className={s.aboutItem}>
                      <Grid.Column width={6}>
                        <strong>User Mapping:</strong>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <code>{username}</code>
                        <Popup
                          position="right center"
                          trigger={
                            <Icon
                              className={s.mappingInfoIcon}
                              color="orange"
                              name="question circle"
                            />
                          }
                          content={
                            <span>
                              User <strong>{authUsername}</strong> is managed by
                              an external directory server, but is associated
                              with the analysis data stored in an Anchore
                              Enterprise Services account&nbsp;
                              <strong>{accountname}</strong> by user
                              mapping&nbsp;
                              <strong>{username}</strong>.
                            </span>
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  ) : null}
                  <Grid.Row className={s.aboutItem}>
                    <Grid.Column width={6}>
                      <strong>Current Session Started:</strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      {moment(sessionStart)
                        .tz(USER_TIMEZONE)
                        .format('ddd, DD MMM YYYY [at] HH:mm:ss z')}{' '}
                      ({moment(sessionStart).fromNow()})
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              {entitlements ? (
                <Grid.Column width="6" className={s.entitlementsCol}>
                  <List className={s.listWrapperItem}>
                    <List.Header className={s.listHeaderItem}>
                      Entitlements
                    </List.Header>
                    {makeEntitlementList(entitlements)}
                  </List>
                  <span className={s.spaceMan} />
                  <span className={s.cameraTripod} />
                </Grid.Column>
              ) : null}
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Divider fitted horizontal className={s.divider}>
          <Icon circular inverted color="teal" name="drivers license" />
        </Divider>
        <Modal.Content style={{ margin: 0, padding: '1rem' }}>
          <Grid columns={2}>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>License ID:</strong>
              </Grid.Column>
              <Grid.Column width={13}>
                <code>{id}</code>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>License Type:</strong>
              </Grid.Column>
              <Grid.Column width={13}>
                <code>{`${type.charAt(0).toUpperCase()}${type.substr(
                  1,
                )}`}</code>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>Expires:</strong>
              </Grid.Column>
              <Grid.Column width={13}>
                {moment(expiration)
                  .tz(USER_TIMEZONE)
                  .format('ddd, DD MMM YYYY [at] HH:mm:ss z')}
                &nbsp;(
                {moment(expiration).fromNow()})
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>Licensed to:</strong>
              </Grid.Column>
              <Grid.Column width={13}>
                {user}
                &nbsp;
                <a href={`mailto:${email}`}>
                  <Icon name="mail" color="teal" />
                  {email}
                </a>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={s.aboutItem}>
              <Grid.Column width={3}>
                <strong>Organization:</strong>
              </Grid.Column>
              <Grid.Column width={13}>{organization}</Grid.Column>
            </Grid.Row>
          </Grid>

          {!isBeforeWarning && (
            <Segment
              size="tiny"
              inverted
              secondary
              color="red"
              textAlign="center"
              className="animate__animated animate__pulse"
            >
              <Icon name="warning sign" />
              Your license will expire&nbsp;
              <strong>{moment(expiration).fromNow()}</strong>
              —contact Anchore Support for a renewal!
            </Segment>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            as="a"
            size="tiny"
            href="https://support.anchore.com"
            icon
            color="blue"
            target="_blank"
            labelPosition="right"
            style={{ margin: 0 }}
          >
            <Icon name="external alternate" />
            Visit Anchore Support
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const showPwdModal = (trigger?: ReactNode) => {
    const { account } = auth;

    return (
      <Modal
        size="mini"
        closeIcon
        trigger={
          trigger || (
            <Dropdown.Item
              key="pwd"
              disabled={account && account.authType !== 'default'}
              className={s.acctMenuItem}
              icon={<Icon name="lock" color="teal" />}
              content="Change Your Password"
              onMouseEnter={() => {
                setCtxpin(false);
                setLnkpin(false);
                setTopnavViewPropsWrapper({
                  contextitm: false,
                  linksitm: false,
                });
              }}
            />
          )
        }
      >
        <Header
          style={{
            fontWeight: 100,
            backgroundColor: 'rgb(54, 130, 224)',
            border: 0,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span className={s.aboutTitle}>Change Your Password</span>
        </Header>
        <Modal.Content>
          <Modal.Description>
            <ChangePassword />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  const showRegistryModal = (trigger?: ReactNode) =>
    permissions && (
      <RegistryList
        permissions={permissions}
        trigger={
          trigger || (
            <Dropdown.Item
              key="registry"
              disabled={!permissions.listRegistries}
              className={s.acctMenuItem}
              icon={<Icon name="address book outline" color="teal" />}
              content="Add / Edit Registry Credentials"
              onMouseEnter={() => {
                setCtxpin(false);
                setLnkpin(false);
                setTopnavViewPropsWrapper({
                  contextitm: false,
                  linksitm: false,
                });
              }}
              onClick={() => {
                setTopnavViewPropsWrapper({
                  registryitm: true,
                });
              }}
            />
          )
        }
      />
    );

  const { navbtns, accountbtn } = app.topnav.viewProps.topnav;
  const { healthCheck } = app;
  const { isEngineVersionGood } = healthCheck;
  const engineVersion =
    isEngineVersionGood && isEngineVersionGood?.engineVersion;
  const { isAuthenticated, account } = auth;
  const isLdapUser = account && account.authType === 'ldap';

  const isSwitched =
    account &&
    account.context &&
    account.context.accountname !== account.accountname;

  return (
    <div className={s.root}>
      <Container>
        <Menu
          inverted
          borderless
          fixed="top"
          className={classNames(
            s.topNavMenu,
            isAuthenticated ? 'pace-border' : '',
          )}
        >
          <Menu.Item as="a" header href="/" className={s.logoWrapper}>
            <span className={s.logo} />
            <span
              className={classNames(
                s.releaseLabel,
                'animate__animated',
                'animate__fadeIn',
              )}
            >
              {engineVersion ? `${engineVersion} / ` : null}
              {uiVersion} UI
            </span>
          </Menu.Item>
          {isAuthenticated && whatsNew ? <WhatsNew data={whatsNew} /> : null}
          {isAuthenticated &&
          isLicenseGood &&
          !isLicenseGood.isBeforeWarning ? (
            <Menu.Item header>
              {showInfoModal(
                <div
                  className={classNames(
                    'animate__animated animate__fadeIn',
                    s.licenseWarningWrapper,
                  )}
                >
                  <Icon color="orange" name="warning sign" />
                  <span>
                    Your license will expire&nbsp;
                    {moment(isLicenseGood.expiration).fromNow()}
                  </span>
                </div>,
              )}
            </Menu.Item>
          ) : null}
          {(() => {
            let navBtnGrpEl: ReactNode = null;
            if (isAuthenticated && navbtns) {
              const fadeCnd = window.scrollY ? 'animate__fadeOut' : 'hide';
              navBtnGrpEl = (
                <Menu.Item header id="navBtnGrp">
                  <div
                    className={classNames(
                      s.shield,
                      navbtns === 'down' ? 'hide' : null,
                    )}
                  />
                  {routeMetadata
                    .filter(
                      (
                        item,
                      ): item is RouteMetadata & {
                        navbar: RouteMetadataNavbar;
                      } =>
                        !!item.navbar &&
                        item.navbar.show !== false &&
                        !item.featureFlag,
                    )
                    .map((item, idx) => {
                      const userPerms = getUserPermsSync(
                        {
                          account,
                          actions: item.navbar.permissions,
                        },
                        false,
                      );

                      const enabled = arePermsGoodForRoute({
                        permissions:
                          userPerms instanceof Error ? false : userPerms,
                        route: item,
                      });

                      // Add a notification icon to the minimized nav control
                      // button when and where applicable
                      let notifyIcon: ReactNode = null;

                      if (
                        item.navbar.name === 'system' &&
                        enabled &&
                        active !== item.navbar.title &&
                        account &&
                        account.isAdmin &&
                        systemStatus
                      ) {
                        notifyIcon = (
                          <StatusIcon
                            className={s.notifyIcon}
                            fitted
                            size="small"
                            variant={healthStatusVariants[systemStatus]}
                            disabled={!enabled}
                          />
                        );
                      }

                      const itemContentEl = (
                        <div
                          className={classNames(
                            s.linkBtn,
                            'animate__animated',
                            navbtns === 'down' ? 'animate__fadeIn' : fadeCnd,
                          )}
                          style={{
                            cursor: navbtns === 'down' ? 'pointer' : 'default',
                            animationDelay:
                              navbtns === 'down'
                                ? `${(idx + 1) * 50}ms`
                                : `0ms`,
                          }}
                        >
                          <Label
                            basic
                            disabled={!enabled}
                            className={classNames(
                              s.topNavBtn,
                              active === item.navbar.title ? s.active : null,
                            )}
                          >
                            <Icon.Group>
                              <Icon
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                                fitted
                                disabled={!enabled}
                                className={
                                  NAV_ICONS?.[
                                    item.navbar.name as keyof typeof NAV_ICONS
                                  ]
                                }
                              />
                              {notifyIcon}
                            </Icon.Group>
                          </Label>
                        </div>
                      );

                      let shouldLink = true;

                      // Inventory shouldn't link if user is on path already
                      if (
                        item.navbar.name === 'inventory' &&
                        window.location.pathname === item.path
                      ) {
                        shouldLink = false;
                      }

                      const itemEl =
                        enabled && shouldLink ? (
                          <Link to={item.path} key={item.navbar.name}>
                            {itemContentEl}
                          </Link>
                        ) : (
                          itemContentEl
                        );

                      return (
                        <Popup
                          key={item.navbar.name}
                          position="bottom center"
                          className="animate__animated animate__fadeIn"
                          hideOnScroll
                          trigger={itemEl}
                          content={
                            <span>
                              {item.navbar.title}
                              {!enabled ? (
                                <>
                                  &nbsp;<strong>(Disabled)</strong>
                                </>
                              ) : (
                                ''
                              )}
                            </span>
                          }
                        />
                      );
                    })}
                </Menu.Item>
              );
            }
            return navBtnGrpEl;
          })()}

          {isAuthenticated && account ? (
            <Menu.Menu position="right">
              <DarkModeToggle />
              {isSwitched ? (
                <Menu.Item style={{ overflow: 'hidden' }}>
                  <Button
                    icon
                    labelPosition="left"
                    className={s.accountBtn}
                    color="orange"
                    size="tiny"
                    disabled={system.isUpdatingAccountContext}
                    onClick={() => {
                      dispatch(
                        updateAccountContext({
                          name: account.accountname,
                        }),
                      ).then((resp: { type: string }) => {
                        if (
                          resp &&
                          resp.type === SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS
                        ) {
                          raiseToast({
                            toastId: `${account.accountname}:${resp.type}`,
                            message: (
                              <span>
                                The account data context for the current session
                                has been has been returned to account&nbsp;
                                <strong>{account.accountname}</strong>.
                              </span>
                            ),
                            level: 'info',
                            icon: 'angle double left',
                            autoClose: 6000,
                            dismissAll: true,
                          });
                        } else {
                          raiseToast({
                            toastId: `${account.accountname}:${resp.type}`,
                            message: (
                              <span>
                                <strong>We&#39;re sorry&hellip;</strong>
                                &nbsp;but due to a service error we were not
                                able to make&nbsp;
                                <strong>{account.accountname}</strong>
                                &nbsp;the new data context.
                              </span>
                            ),
                            level: 'error',
                            autoClose: 8000,
                            dismissAll: true,
                          });
                        }
                      });
                    }}
                  >
                    <span style={{ fontWeight: 500, marginRight: '0.25rem' }}>
                      Return to context
                    </span>
                    <strong>{account.accountname}</strong>
                    <Icon name="angle double left" />
                  </Button>
                </Menu.Item>
              ) : null}
              {account.username ? (
                <Menu.Item style={{ overflow: 'hidden' }}>
                  <Label
                    as="a"
                    onClick={() => {
                      raiseToast(false);
                      if (!ctxpin) {
                        setTopnavViewPropsWrapper({
                          accountbtn: !accountbtn,
                          contextitm: false,
                          linksitm: false,
                        });
                      }
                    }}
                    className={s.accountBtn}
                    color={account && account.isAdmin ? 'violet' : 'blue'}
                    data-test="topnav-account-pulldown"
                  >
                    <span
                      className={
                        account && account.isAdmin ? s.rocket : s.robot
                      }
                    />
                    <span
                      style={{
                        paddingLeft: '1.25rem',
                        color: 'rgba(255,255,255,0.85)',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {account && account.isAdmin ? (
                        <Icon
                          name="chess queen"
                          className={s.accountBtnAdminIcon}
                        />
                      ) : null}
                      <span
                        style={{
                          fontWeight: 500,
                          color: 'rgba(255,255,255,0.85)',
                          lineHeight: '2rem',
                        }}
                      >
                        Account
                      </span>
                      &nbsp;
                      <span style={{ lineHeight: '2rem' }}>
                        {account.accountname}
                      </span>
                    </span>
                    {account.context &&
                    (account.context.accountname !== account.accountname ||
                      system.isUpdatingAccountContext) ? (
                      <>
                        <span className={s.verticalDivider} />
                        <span
                          style={{
                            fontWeight: 500,
                            color: 'rgba(255,255,255,0.85)',
                            lineHeight: '2rem',
                          }}
                        >
                          Context
                        </span>
                        &nbsp;
                        {system.isUpdatingAccountContext ? (
                          <Loader
                            size="tiny"
                            active
                            inline
                            inverted
                            style={{
                              marginLeft: '0.25rem',
                              lineHeight: '2rem',
                            }}
                          />
                        ) : (
                          <>
                            <span style={{ lineHeight: '2rem' }}>
                              {account.context.accountname}
                            </span>
                            {!account.context.isEnabled ? (
                              <span
                                style={{
                                  fontWeight: 500,
                                  color: 'rgba(255,255,255,0.85)',
                                  marginLeft: '0.25rem',
                                  lineHeight: '2rem',
                                }}
                              >
                                (disabled)
                              </span>
                            ) : null}
                          </>
                        )}
                      </>
                    ) : null}
                    <span className={s.verticalDivider} />
                    <span
                      style={{
                        fontWeight: 500,
                        color: 'rgba(255,255,255,0.85)',
                        lineHeight: '2rem',
                      }}
                    >
                      Username
                    </span>
                    &nbsp;
                    <span style={{ lineHeight: '2rem' }}>
                      {account.authUsername}
                    </span>
                    <Icon
                      name={`caret ${accountbtn ? 'up' : 'down'}`}
                      className={s.accountBtnCaretIcon}
                    />
                  </Label>
                </Menu.Item>
              ) : null}
              <Dropdown
                open={
                  system.isUpdatingAccountContext === true
                    ? false
                    : ctxpin || lnkpin || accountbtn
                }
                icon={false}
                style={{ padding: 0, width: 0, fontSize: '0.95rem' }}
                item
                closeOnChange={false}
                onClose={() => {
                  if (!ctxpin) {
                    setTimeout(() => {
                      setTopnavViewPropsWrapper({
                        accountbtn: false,
                        contextitm: false,
                        linksitm: false,
                      });
                      setCtxpin(false);
                    }, 0);
                  }
                }}
              >
                <Dropdown.Menu
                  style={{ borderTop: 'none', borderBottomRightRadius: 0 }}
                  className={classNames(
                    'animate__animated animate__fadeIn',
                    s.acctMenu,
                  )}
                >
                  <Dropdown.Divider style={{ margin: 0 }} />
                  {showInfoModal()}
                  <Dropdown.Divider style={{ margin: 0 }} />
                  {showAccountSwitch()}
                  {showRegistryModal()}
                  {showCustomLinks()}
                  {showPwdModal()}
                  <ConditionalWrap
                    condition={isLdapUser}
                    wrap={children => (
                      <Popup trigger={children} position="right center">
                        API key creation and management is only available to
                        non-LDAP users.
                      </Popup>
                    )}
                  >
                    <Dropdown.Item
                      content={
                        <span className={s.apiKeysMenuItem}>
                          API Keys
                          {isLdapUser && (
                            <Icon
                              name="info circle"
                              color="orange"
                              className={s.infoIcon}
                            />
                          )}
                        </span>
                      }
                      className={s.acctMenuItem}
                      icon={<Icon name="key" color="teal" />}
                      key="api-keys"
                      disabled={isLdapUser}
                      onMouseEnter={() => {
                        setCtxpin(false);
                        setLnkpin(false);
                        setTopnavViewPropsWrapper({
                          contextitm: false,
                          linksitm: false,
                        });
                      }}
                      onClick={() =>
                        !isLdapUser &&
                        dispatch(
                          showModal({
                            type: 'apiKeys',
                            props: {
                              account: account.accountname,
                              userName: account.username,
                            },
                          }),
                        )
                      }
                    />
                  </ConditionalWrap>
                  <Dropdown.Item
                    key="docs"
                    className={s.acctMenuItem}
                    icon={<Icon name="book" color="teal" />}
                    content="Documentation"
                    onMouseEnter={() => {
                      setCtxpin(false);
                      setLnkpin(false);
                      setTopnavViewPropsWrapper({
                        contextitm: false,
                        linksitm: false,
                      });
                    }}
                    onClick={() => {
                      window.open('https://docs.anchore.com', '_blank');
                    }}
                  />
                  <Dropdown.Item
                    key="logout"
                    className={s.acctMenuItem}
                    icon={<Icon name="sign out" color="teal" />}
                    content="Log Out"
                    onMouseEnter={() => {
                      setCtxpin(false);
                      setLnkpin(false);
                      setTopnavViewPropsWrapper({
                        contextitm: false,
                        linksitm: false,
                      });
                    }}
                    onClick={() => {
                      dispatch(
                        logout({
                          server: true,
                        }),
                      );
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          ) : null}
        </Menu>
        {permissions && <ApiKeysModal permissions={permissions} />}
      </Container>
    </div>
  );
};

export default withStyles(s)(Topnav);
