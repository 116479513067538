import type { AsyncThunk, CaseReducer } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type {
  AsyncThunkFulfilledActionCreator,
  AsyncThunkPendingActionCreator,
  AsyncThunkRejectedActionCreator,
  GetThunkAPI,
} from '@reduxjs/toolkit/dist/createAsyncThunk';
import { compile } from 'path-to-regexp';
import type { ReactNode } from 'react';
import request, {
  type Response,
  type SuperAgentRequest,
  type SuperAgentStatic,
} from 'superagent';
import type { RequireAtLeastOne } from 'type-fest';

import type {
  EntityActionParams,
  EntityServiceResponse,
  RestVerb,
  ServiceError,
} from '@models';
import raiseToast from '@shared/components/Toast';

import type { AppDispatch, RootState } from '../store';

import debounceAction from './debounceAction';

export type EntityReducerOptions<State, Return, Params> = {
  pending?: CaseReducer<
    State,
    ReturnType<AsyncThunkPendingActionCreator<Params>>
  >;
  rejected?: CaseReducer<
    State,
    ReturnType<AsyncThunkRejectedActionCreator<Params>>
  >;
  fulfilled?: CaseReducer<
    State,
    ReturnType<AsyncThunkFulfilledActionCreator<Return, Params>>
  >;
};

type EntityActionNameOptions = {
  /**
   * A unique name for the thunk action type. This option provides full control
   * of the name where needed, rather than allowing it to be built internally.
   *
   * If left unset, the action name will be constructed from truthy values of
   * `sliceName`/`stateKey`, and the `verb`. These are optional, so take care to
   * ensure that the values form an action name that is unique across the whole
   * store, as collisions will cause errors.
   */
  actionName?: string;
  /**
   * Unique name that identifies this slice in the store. This should usually be
   * the same one passed to `createSlice`.
   *
   * This is used to construct the action name when `actionName` is unset. It
   * must provide sufficient uniqueness (once combined with other options) to
   * avoid collisions.
   */
  sliceName?: string;
  /**
   * A path-style pointer to the storage target in the state object. Define
   * nested properties with a forward slash. Also used to construct the action
   * name when `actionName` is unset.
   *
   * Optionally, the path may be parameterized. When used with accompanying
   * reducer factories, it is compiled with the thunk parameters using
   * `path-to-regexp`, providing control over ID-based storage targets.
   *
   * When used in the action name, a parameterized state key is never compiled.
   */
  stateKey?: string;
};

export type EntityConfig<State, Return, Params> =
  RequireAtLeastOne<EntityActionNameOptions> & {
    verb?: RestVerb;
    /**
     * A string or function that returns a string representing the service path
     * for the async request.
     *
     * When provided as a string, the path may also be parameterized. It will be
     * compiled with the thunk parameters using `path-to-regexp`.
     */
    servicePath: string | ((params: Params) => string);
    condition?: (state: RootState) => boolean;
    customRequest?: (
      req: SuperAgentStatic,
      path: string,
      params: Params,
    ) => SuperAgentRequest;
    toastOnSuccess?: (
      params: Params,
      resp: Return,
      thunkApi: GetThunkAPI<{
        state: RootState;
        dispatch: AppDispatch;
        rejectValue: ServiceError;
      }>,
    ) => ReactNode;
    toastOnFailure?: (
      params: Params,
      error: any,
      thunkApi: GetThunkAPI<{
        state: RootState;
        dispatch: AppDispatch;
        rejectValue: ServiceError;
      }>,
    ) => ReactNode;
    getToastId?: (params: Params) => string;
    actionDescription?: string;
    onSuccess?: (
      response: Return,
      params: Params,
      thunkApi: GetThunkAPI<{
        state: RootState;
        dispatch: AppDispatch;
        rejectValue: ServiceError;
      }>,
    ) => void;
    reducerOptions?: EntityReducerOptions<State, Return, Params>;
    debounce?: boolean;
  };

export interface EntityAction<State, Return, Params>
  extends EntityReducerOptions<State, Return, Params> {
  stateKey: string;
  thunk: AsyncThunk<
    Return,
    Params,
    {
      state: RootState;
      dispatch: AppDispatch;
      rejectValue: ServiceError;
    }
  >;
  verb: RestVerb;
}

export type CreateEntityActionOptions<Actions> = {
  [K in keyof Actions]: EntityAction<any, any, any>;
};

const defaultRequest = <Params extends EntityActionParams | void>(
  verb: RestVerb,
  path: string,
  params?: Params,
) =>
  params && (verb === 'post' || verb === 'put' || verb === 'patch')
    ? request[verb](path).send(params)
    : request[verb](path);

const setHeaders = (req: SuperAgentRequest) => {
  if (window.sock?.id) return req.set('anchore-socketid', window.sock?.id);
  return req;
};

const compileServicePath = <Params extends EntityActionParams | void>(
  servicePath: string | ((params: Params) => string),
  params: Params,
) =>
  typeof servicePath === 'function'
    ? servicePath(params)
    : compile(servicePath, { encode: encodeURIComponent })(params ?? {});

export const createEntityAction = <
  // To avoid having to pass the base params every time, the incoming type is
  // intentionally loose here, and merged with the base params internally.
  Params extends object | void,
  Return extends EntityServiceResponse<any>,
  State = RootState,
>({
  servicePath,
  condition,
  customRequest,
  stateKey = '',
  sliceName = '',
  verb = 'get',
  toastOnSuccess,
  toastOnFailure,
  getToastId,
  actionDescription,
  actionName,
  onSuccess,
  reducerOptions,
  debounce,
}: EntityConfig<State, Return, EntityActionParams<Params>>): EntityAction<
  State,
  Return,
  EntityActionParams<Params>
> => {
  const typePrefixParts = [sliceName, stateKey, verb].filter(Boolean);
  const thunkTypePrefix = actionName || typePrefixParts.join('/');
  const toastIdPrefix = typePrefixParts.join('-');

  const thunk = createAsyncThunk<
    Return,
    EntityActionParams<Params>,
    {
      state: RootState;
      dispatch: AppDispatch;
      rejectValue: ServiceError;
    }
  >(
    thunkTypePrefix,
    async (params, thunkAPI) => {
      try {
        const path = compileServicePath(servicePath, params);

        const req = customRequest
          ? customRequest(request, path, params)
          : defaultRequest(verb, path, params);

        let resp: Response;
        const abortRequest = () => {
          req.abort();
        };
        if (debounce) {
          thunkAPI.signal.addEventListener('abort', abortRequest);
          try {
            resp = await setHeaders(req);
          } finally {
            thunkAPI.signal.removeEventListener('abort', abortRequest);
          }
        } else {
          resp = await setHeaders(req);
        }

        if (toastOnSuccess && (params?.showToastOnSuccess ?? true)) {
          const toastId = getToastId ? `-${getToastId?.(params)}` : '';
          raiseToast({
            toastId: `${toastIdPrefix}-success${toastId}`,
            message: toastOnSuccess(params, resp.body, thunkAPI),
            level: 'success',
            icon: 'save',
            autoClose: 8000,
            dismissAll: true,
          });
        }
        if (onSuccess) {
          onSuccess(resp.body, params, thunkAPI);
        }
        return resp.body;
      } catch (error: any) {
        const resp: Response = error.response;
        if (resp) {
          const contentType = resp?.headers['content-type'];
          const statusCode = resp?.statusCode;
          const data = resp.body?.data;
          const errorObject =
            statusCode === 524
              ? {
                  message:
                    'The connection was timed out between the browser and the web service',
                }
              : contentType.includes('application/json')
                ? data || error
                : error;
          if (toastOnFailure && (params?.showToastOnFailure ?? true)) {
            const toastId = getToastId ? `-${getToastId?.(params)}` : '';
            raiseToast({
              toastId: `${toastIdPrefix}-failure${toastId}`,
              title: actionDescription
                ? `${actionDescription} Failed`
                : undefined,
              message: toastOnFailure(params, errorObject, thunkAPI),
              level: 'error',
              icon: 'warning sign',
              autoClose: 8000,
              dismissAll: true,
            });
          }

          return thunkAPI.rejectWithValue(errorObject);
        }
        throw error;
      }
    },
    {
      condition: (_, thunkAPI) => {
        const state = thunkAPI.getState();
        return condition ? condition(state) : true;
      },
      dispatchConditionRejection: true,
    },
  );

  return {
    thunk: debounce ? debounceAction(thunk) : thunk,
    stateKey,
    verb,
    ...reducerOptions,
  };
};

export default createEntityAction;
