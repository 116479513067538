/* eslint-disable camelcase */
// To extend ServiceRequest, you can find our custom declaration in global.d.ts
export type { Request as ServiceRequest } from 'express';

export interface ServiceRequestOptions {
  session: any; // TODO: Extend with an accurate type instead of using any
  sock: any;
  cache: any;
  anchoreCustomProps: {
    authHeaders: AuthHeaders;
    serviceHeaders: ServiceHeaders;
  };
  anchore?: { appDB: any };
}

export interface ServiceResponse {
  status: 'success' | 'error';
}

export interface EntityServiceResponse<T> extends ServiceResponse {
  // TODO: This `body` seems unnecessary and confusing. Consider removing it.
  body: {
    data: T;
  };
}

export type {
  Application,
  ApplicationVersion,
  FeedMetadata,
  ServiceList,
} from '@/api/api';

export interface ServiceError {
  statusCode?: number;
  message?: string;
  type?: string;
}

export type MaybeParsedQueryValue<
  T,
  IsParsed extends boolean = false,
> = IsParsed extends true ? string : T;

export type PaginationSort<
  Key extends string = string,
  Value extends string | boolean = boolean,
> = Partial<Record<Key, Value>>;

/**
 * Pagination params that can represent parsed or unparsed variants. By default
 * it represents the unparsed interface. Setting `IsParsed` will coerce all of
 * the types to strings.
 */
export type PaginationParamsBase<
  IsParsed extends boolean = false,
  SortableFieldName extends string = string,
> = {
  limit?: MaybeParsedQueryValue<number, IsParsed>;
  filter?: MaybeParsedQueryValue<string, IsParsed>;
  page?: MaybeParsedQueryValue<number, IsParsed>;
  sort?: PaginationSort<
    SortableFieldName,
    MaybeParsedQueryValue<boolean, IsParsed>
  >;
};

export type PaginationParams<SortableFieldName extends string = string> =
  PaginationParamsBase<false, SortableFieldName>;

/**
 * A parsed query params object on the server, containing pagination values
 */
export type ParsedPaginationParams<SortableFieldName extends string = string> =
  PaginationParamsBase<true, SortableFieldName>;

export type DataUpdateType = 'create' | 'update' | 'delete';

export type TotalRows = {
  totalRows: number;
};

export type PaginatedEntityResponse<T> = EntityServiceResponse<T> & TotalRows;

export interface DataUpdateEvent {
  entity: string;
  entityId: string;
  updateType: DataUpdateType;
  originatingSocketId: string;
}

/**
 * Data entity fetched from the backend
 */
export type DataEntity<T, K extends string = string, D = Record<K, T>> = {
  isLoading: boolean;
  isUpdating: boolean;
  data: D | null;
  error?: ServiceError;
  stale?: Record<K, DataUpdateType>;
};

export const nullDataEntity: Readonly<DataEntity<any, string, null>> =
  Object.freeze({
    isLoading: false,
    isUpdating: false,
    data: null,
  });

export type RestVerb = 'get' | 'post' | 'put' | 'delete' | 'patch';

export type AsyncStatus =
  | 'initial'
  | 'loading'
  | 'creating'
  | 'updating'
  | 'deleting'
  | 'ready'
  | 'error';

export interface AsyncState<ErrorType = ServiceError> {
  status: AsyncStatus;
  error?: ErrorType;
  stale?: boolean;
}

export type WithAsyncState<
  T,
  ErrorType = ServiceError,
> = AsyncState<ErrorType> & T;

export type StatusEntity<T> = WithAsyncState<{ data: T | null }>;

export const nullStatusEntity: Readonly<StatusEntity<any>> = Object.freeze({
  status: 'initial',
  data: null,
});

export type EntityActionParams<T = unknown> = T extends void
  ? T
  : {
      showToastOnSuccess?: boolean;
      showToastOnFailure?: boolean;
    } & T;

export interface TableViewProps<SortableFieldName extends string = string> {
  page: number;
  pageSize: number;
  filterStr: string;
  sort?: PaginationSort<SortableFieldName>;
}

export const initialTableViewProps: Readonly<TableViewProps> = Object.freeze({
  page: 0,
  pageSize: 10,
  filterStr: '',
});

export type FieldType =
  | 'String'
  | 'StringList'
  | 'Enum'
  | 'EnumList'
  | 'Boolean'
  | 'DateTime'
  | 'TimePeriod'
  | 'Int';

type FieldBase =
  | {
      typeName?: 'String';
      data?: string;
      example?: string;
    }
  | {
      typeName: 'StringList';
      data?: string[];
      example?: string[];
    }
  | {
      typeName: 'Enum';
      data?: string;
      enumValues: string[];
      example?: string;
    }
  | {
      typeName: 'EnumList';
      data?: string[];
      enumValues: string[];
      example?: string[];
    }
  | {
      typeName: 'Boolean';
      data?: boolean;
      example?: boolean;
    }
  | {
      typeName: 'DateTime';
      data?: string;
      example?: string;
    }
  | {
      typeName: 'TimePeriod';
      data?: string;
      example?: string;
    }
  | {
      typeName: 'Int';
      data?: number;
      example?: number;
    };
export type FieldValue = FieldBase['data'];
export type Field = FieldBase & {
  key: string;
  name?: string;
  category?: string;
  description?: string;
  required?: boolean;
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
