import {
  type PayloadAction,
  createSlice,
  createAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import type {
  StatusEntity,
  EntityServiceResponse,
  InventoryClusterList,
  InventorySummary,
  TableViewProps,
  DeepPartial,
  InventoryVulnerabilityList,
  InventoryVulnSummary,
  FetchInventoryImagesParams,
  FetchInventoryClustersParams,
  FetchInventoryVulnSummaryParams,
  FetchInventoryVulnerabilitiesParams,
  GetInventoryVulnerabiltiesResponse,
  GetInventoryImagesResponse,
  GetInventoryVulnSummaryResponse,
  InventoryImageItem,
} from '@models';
import { initialTableViewProps, nullStatusEntity } from '@models';

import { SET_IMAGE_ADD_SUCCESS } from './actionTypes';
import {
  createEntityAction,
  createStatusEntityReducers,
  getEntityActions,
} from './common';

type TableViewPropsSingleColumnSort = Omit<TableViewProps, 'sort'> & {
  sort: {
    id: string;
    desc: boolean;
  };
};

export interface InventoriesState {
  clusters: StatusEntity<InventoryClusterList>;
  images: StatusEntity<InventoryImageItem[]> & {
    totalRows?: number;
  };
  imagesLastUpdated?: string;
  vulnerabilities: StatusEntity<InventoryVulnerabilityList>;
  vulnSummary: StatusEntity<InventoryVulnSummary>;
  viewProps: {
    compliance: Partial<Record<keyof InventorySummary, boolean>>;
    vulnerabilities: Partial<Record<keyof InventoryVulnSummary, boolean>>;
    tables: {
      clusters: TableViewPropsSingleColumnSort;
      namespaces: TableViewPropsSingleColumnSort;
      images: TableViewProps;
      vulnerabilities: TableViewPropsSingleColumnSort;
    };
  };
}

export const initialState: InventoriesState = {
  clusters: nullStatusEntity,
  images: nullStatusEntity,
  vulnerabilities: nullStatusEntity,
  vulnSummary: nullStatusEntity,
  viewProps: {
    compliance: {
      non_compliant_images: true,
      compliant_images: true,
      not_evaluated_images: true,
    },
    vulnerabilities: {
      critical: true,
      high: true,
      medium: true,
      low: true,
      negligible: true,
      unknown: true,
    },
    tables: {
      clusters: {
        ...initialTableViewProps,
        sort: { id: 'name', desc: true },
      },
      namespaces: {
        ...initialTableViewProps,
        sort: { id: 'imageCountNamespaces', desc: true },
      },
      images: {
        ...initialTableViewProps,
        sort: { lastAnalyzed: true },
      },
      vulnerabilities: {
        ...initialTableViewProps,
        sort: { id: 'severity', desc: true },
      },
    },
  },
};

export type InventoryViewProps = InventoriesState['viewProps'];
export type InventoryVulnViewProps = InventoryViewProps['vulnerabilities'];
export type InventoryComplianceViewProps = InventoryViewProps['compliance'];

const setImagesLastUpdated = createAction<string>(
  'inventories/setImagesLastUpdated',
);

const entities = {
  fetchInventoryClusters: createEntityAction<
    FetchInventoryClustersParams,
    EntityServiceResponse<InventoryClusterList>
  >({
    sliceName: 'inventories',
    stateKey: 'clusters',
    servicePath: () => `/service/inventories/clusters`,
  }),
  fetchInventoryImages: createEntityAction<
    FetchInventoryImagesParams,
    GetInventoryImagesResponse
  >({
    sliceName: 'inventories',
    stateKey: 'images',
    servicePath: () => `/service/inventories/images`,
    customRequest: (request, path, params) =>
      request.get(path).query({
        cluster: params.cluster,
        namespace: params.namespace,
        limit: params.limit,
        filter: params.filter,
        page: params.page,
        sort: params.sort,
        compliant: params.compliant,
        evaluated: params.evaluated,
        severity: params.severity,
      }),
    debounce: true,
  }),
  fetchInventoryVulnerabilities: createEntityAction<
    FetchInventoryVulnerabilitiesParams,
    GetInventoryVulnerabiltiesResponse
  >({
    sliceName: 'inventory',
    stateKey: 'vulnerabilities',
    servicePath: () => `/service/inventories/vulnerabilities`,
    customRequest: (request, path, params) =>
      request
        .get(path)
        .query({ cluster: params.cluster, namespace: params.namespace }),
  }),
  fetchInventoryVulnSummary: createEntityAction<
    FetchInventoryVulnSummaryParams,
    GetInventoryVulnSummaryResponse
  >({
    sliceName: 'inventory',
    stateKey: 'vulnSummary',
    servicePath: () => `/service/inventories/vulnerability_summary`,
    customRequest: (request, path, params) =>
      request
        .get(path)
        .query({ cluster: params.cluster, namespace: params.namespace }),
  }),
};
export const {
  fetchInventoryClusters,
  fetchInventoryVulnerabilities,
  fetchInventoryImages,
  fetchInventoryVulnSummary,
} = getEntityActions(entities);

const inventoriesSlice = createSlice({
  name: 'inventories',
  initialState,
  reducers: {
    setInventoryViewProps: (
      state,
      action: PayloadAction<DeepPartial<InventoryViewProps>>,
    ) => {
      state.viewProps = merge(state.viewProps, action.payload, {
        customMerge: key => {
          // Replace the sort object instead of merging it
          if (key === 'sort') return (a, b) => b;
          return undefined;
        },
      }) as InventoryViewProps;
    },
    clearInventoryImages: state => {
      state.images = {
        ...state.images,
        data: [],
        totalRows: 0,
      };
    },
  },
  extraReducers: builder => {
    createStatusEntityReducers(entities, builder);

    builder
      .addCase(SET_IMAGE_ADD_SUCCESS, state => {
        state.imagesLastUpdated = new Date().toISOString();
      })
      .addCase(setImagesLastUpdated, (state, action) => {
        state.imagesLastUpdated = action.payload;
      });
  },
});

export const { setInventoryViewProps, clearInventoryImages } =
  inventoriesSlice.actions;

export default inventoriesSlice.reducer;
