import withStyles from 'isomorphic-style-loader/withStyles';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Icon, Popup } from 'semantic-ui-react';
import { useLocalStorage } from 'usehooks-ts';

import darkModeProps from './darkModeProps.json';
import s from './DarkModeToggle.scss';

const DarkModeToggle = () => {
  // The `useLocalStorage` hook is used to store the current dark mode state in
  // localStorage and returns a function to update the stored value.
  const [isDarkMode, setIsDarkMode] = useLocalStorage('darkmode', false);

  // Hook to store the availability of the Dark Reader extension
  const [darkReaderAvailable, setDarkReaderAvailable] = useState(false);

  // Check if the Dark Reader extension is available and if it is, set the
  // initial state of the toggle based on the current state of the extension.
  // This is in a timeout to ensure that the is loaded (in an effect within
  // `Layout` using `require` ugh...) before we try to access it.
  useEffect(() => {
    setTimeout(() => {
      if (process.env.BROWSER && window.App?.darkReader) {
        const isEnabled = window.App.darkReader.isEnabled();
        setDarkReaderAvailable(true);
        setIsDarkMode(isEnabled);
      }
    }, 0);
  }, []);

  // Update the Dark Reader extension state based on changes that were made in a
  // different tab
  useEffect(() => {
    if (process.env.BROWSER && window.App?.darkReader) {
      const { darkReader } = window.App;
      if (isDarkMode !== null && darkReader.isEnabled() !== isDarkMode) {
        setIsDarkMode(isDarkMode);
        if (isDarkMode) {
          darkReader.enable(darkModeProps);
        } else {
          darkReader.disable();
        }
      }
    }
  }, [isDarkMode]);

  // Function to switch between dark and light mode. It also saves the current
  // mode to localStorage, so that it can be restored on page reload.
  const switchMode = (ev, data) => {
    const layoutEl = document.getElementById('layout')!;
    if (data.checked) {
      layoutEl.classList.add('dark');
      window.App.darkReader.enable(darkModeProps);
    } else {
      layoutEl.classList.remove('dark');
      window.App.darkReader.disable();
    }
    setIsDarkMode(data.checked!);
  };

  // The actual toggle component. It is only rendered if the Dark Reader
  // component is available.
  const CheckboxExampleSlider = () =>
    darkReaderAvailable ? (
      <div className={s.root}>
        <span>
          <Popup
            content="Here comes the sun, and I say, it's alright..."
            position="bottom center"
            mouseEnterDelay={1000}
            trigger={
              <Button
                icon
                color="black"
                onClick={ev => switchMode(ev, { checked: false })}
              >
                <Icon name="sun" color="yellow" className={s.sun} />
              </Button>
            }
          />
          <Checkbox
            slider
            className={isDarkMode ? s.toggleDark : s.toggleLight}
            checked={isDarkMode}
            onChange={switchMode}
          />
          <Popup
            content="Hello darkness my old friend..."
            position="bottom center"
            mouseEnterDelay={1000}
            trigger={
              <Button
                icon
                color="black"
                onClick={ev => switchMode(ev, { checked: true })}
              >
                <Icon name="moon" color="yellow" />
              </Button>
            }
          />
        </span>
      </div>
    ) : null;

  return CheckboxExampleSlider();
};

export default withStyles(s)(DarkModeToggle);
