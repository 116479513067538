import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Button, Image } from 'semantic-ui-react';

import type { StepsConfig } from '../tourConfig';

import s from './TourSteps.scss';

const stepStyles = {
  options: {
    width: '65rem',
  },
};

const robotEl = (
  <Image src="/img/robot_reading.svg" className={s.robotReadingImg} />
);

const Step1 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        Anchore’s <strong>Policy Engine</strong> offers a wide range of{' '}
        <em>gates</em> which can be used to evaluate the content of an SBOM and
        take an action in response. Gates can look at data or metadata in the
        SBOM, the results of the security analysis (for vulnerabilities or
        malware), and even content within files such as secrets.
      </p>
    </div>
  );
};

const Step2 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        The policy engine can then send a{' '}
        <Button color="red" size="tiny">
          STOP
        </Button>{' '}
        command to your CI/CD pipeline, prevent images being deployed, or
        generate warnings for application or security teams.
      </p>
    </div>
  );
};

const Step3 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        By default, Anchore provides a basic policy to look for critical
        vulnerabilities. Users can customize this pack or purchase Policy Packs
        for use with NIST, FedRAMP, or US DoD compliance.
      </p>
      <p>
        For more information on the Policy Engine, please see the documentation.
      </p>
    </div>
  );
};

const Step4 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      <p>
        To add a new policy to the system, click the{' '}
        <strong>Create New Policy</strong> button. You will be asked to provide
        some details about your new policy before being transferred to the
        policy editing view.
      </p>
      <p>
        From the editing view, you can add compliance rules, provide allowlist
        exclusions, and map the policy to your sources and images.
      </p>
    </div>
  );
};

const Step5 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      <p>
        You can also import policies exported from other locations by using the{' '}
        <strong>Import Policy</strong> control. The contents of the policy will
        be displayed and validated prior to upload.
      </p>
    </div>
  );
};

const Step6 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      <p>
        Get started by clicking on the <strong>Edit</strong> control for the
        current active policy to view the rules, allowlists, and mappings that
        are used to produce compliance decisions for analyzed sources and images
        in the system.
      </p>
    </div>
  );
};

const Step7 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      <p>
        To activate a different policy, click the radio button associated with
        the item. The one shown here is currently active.
      </p>
    </div>
  );
};

export default (): StepsConfig => ({
  id: 'policy',
  steps: [
    {
      styles: stepStyles,
      content: <Step1 />,
      placement: 'center',
      target: 'body',
    },
    {
      styles: stepStyles,
      content: <Step2 />,
      placement: 'center',
      target: 'body',
    },
    {
      styles: stepStyles,
      content: <Step3 />,
      placement: 'center',
      target: 'body',
    },
    {
      content: <Step4 />,
      target: '.ui.segment:last-child .right.aligned.column button:last-child',
    },
    {
      content: <Step5 />,
      target: '.ui.segment:last-child .right.aligned.column button:first-child',
    },
    {
      content: <Step6 />,
      target:
        '.rt-tr:has(.ui.green.ribbon) .rt-td:last-child > div > a:last-child',
    },
    {
      content: <Step7 />,
      target: '.ui.checked.radio input',
    },
  ],
});
