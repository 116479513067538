// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react-joyride-portal,#react-joyride-step-0,#show-view-tour{display:none!important}.Zwsbb{box-shadow:none!important}.jHQNT{border:none!important}.C02MF{align-items:center;display:flex;justify-content:center;min-height:calc(100vh - 19.75rem);width:100%}.C02MF .Ts3ae{line-height:27.2px;line-height:1.7rem;text-shadow:1px 1px #fff;white-space:pre-line}.C02MF.uuOYw{min-height:calc(100vh - 13.6rem)}.C02MF.uuOYw>div{min-height:calc(100vh - 16rem)}.C02MF.i3t9T{border:0!important;min-height:calc(100vh - 20rem);padding:0!important}.C02MF .vx8AA{color:#2185d0;cursor:pointer;font-size:15.2px;font-size:.95rem;position:absolute;right:32px;right:2rem;top:16px;top:1rem;z-index:1}.C02MF .hGtbr{font-size:13.6px;font-size:.85rem;margin:0;text-align:left;white-space:pre-wrap}.C02MF p{color:#000000de;font-size:20px;font-size:1.25rem;line-height:32px;line-height:2rem;margin:0!important;text-shadow:1px 1px #fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noBoxShadow": `Zwsbb`,
	"noBorder": `jHQNT`,
	"root": `C02MF`,
	"errorMsgSegment": `Ts3ae`,
	"base": `uuOYw`,
	"inline": `i3t9T`,
	"detailLink": `vx8AA`,
	"jsonStrFmt": `hGtbr`
};
module.exports = ___CSS_LOADER_EXPORT___;
