import type { Reducer, AnyAction } from 'redux';

import { SET_TOUR_PROPS } from '../actionTypes';

export interface TourPathProps {
  isDisabled: boolean;
  showIntro: boolean;
}

export interface TourState {
  tourProps:
    | {
        '/*': TourPathProps;
        '/applications': TourPathProps;
        '/artifacts/image': TourPathProps;
        '/inventory/kubernetes': TourPathProps;
        '/policy': TourPathProps;
        '/policy/:policyId': TourPathProps;
        '/reports': TourPathProps;
        '/reports/new': TourPathProps;
      }
    | false;
}

export const defaultState: TourState = {
  tourProps: false,
};

const reducer: Reducer<TourState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_TOUR_PROPS:
      return {
        ...state,
        tourProps: action.props,
      };

    default:
      return state;
  }
};

export default reducer;
