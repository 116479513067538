import type { TupleToUnion } from 'type-fest';

export interface FriendlyServiceMetadata {
  description: string;
  friendlyName: string;
  isRequired: boolean;
  order: number;
}

export const serviceMetadata = {
  apiext: {
    order: 1,
    friendlyName: 'Core API',
    isRequired: true,
    description:
      'The primary API for the entire system. It is used to analyze images, get policy evaluations, manage accounts/users, and much more.',
  },
  catalog: {
    order: 2,
    friendlyName: 'Catalog',
    isRequired: true,
    description:
      'The primary state manager of the system and owns both the state machines for images as well as the document archive interface used to store large, unstructured documents like JSON outputs from analysis.',
  },
  policy_engine: {
    order: 3,
    friendlyName: 'Policy Engine',
    isRequired: true,
    description:
      'Loads the result of an image analysis, normalizes and structures the data in a way that makes it quickly searchable, scans for vulnerabilities in the found artifacts of the image, and provides fast policy evaluation over that data.',
  },
  simplequeue: {
    order: 4,
    friendlyName: 'SimpleQueue',
    isRequired: true,
    description:
      'A PostgreSQL-backed queue service that the other components use for task execution, notifications, and other asynchronous operations.',
  },
  analyzer: {
    order: 5,
    friendlyName: 'Analyzer',
    isRequired: true,
    description:
      'The component that does all of the image download and analysis heavy-lifting. It receives work from the SimpleQueue service by polling specific queues, executes image analysis, then uploads the results to the catalog and the policy engine when complete.',
  },
  data_syncer: {
    order: 6,
    friendlyName: 'Data Syncer',
    isRequired: true,
    description:
      'Synchronizes data from the Anchore hosted feeds service to the local enterprise installation. An example is the "Vulnerabilities" dataset.',
  },
  reports: {
    order: 7,
    friendlyName: 'Reports',
    isRequired: false,
    description:
      'Aggregates data from Anchore Enterprise Services to provide insightful analytics and metrics for account-wide artifacts.',
  },
  reports_worker: {
    order: 8,
    friendlyName: 'Reports Worker',
    isRequired: false,
    description:
      'An internal component which loads and refreshes the reporting database from API calls to the rest of the system.',
  },
  notifications: {
    order: 9,
    friendlyName: 'Notifications',
    isRequired: false,
    description:
      'Consumes system, user and resource related events from Anchore Enterprise Services, and sends notifications to third-party systems configured by users.',
  },
} satisfies Record<string, FriendlyServiceMetadata>;

export type ServiceName = keyof typeof serviceMetadata;

export const serviceNames = Object.keys(serviceMetadata) as ServiceName[];

export const requiredServiceNames = Object.entries(serviceMetadata).reduce<
  ServiceName[]
>((results, [serviceName, { isRequired }]) => {
  if (!isRequired) return results;

  return [...results, serviceName as ServiceName];
}, []);

export const SERVICE_STATUS_MESSAGES = [
  'registered',
  'available',
  'unavailable',
  'orphaned',
] as const;

export const [
  SERVICE_REGISTERED,
  SERVICE_AVAILABLE,
  SERVICE_UNAVAILABLE,
  SERVICE_ORPHANED,
] = SERVICE_STATUS_MESSAGES;

export type ServiceStatusMessage = TupleToUnion<typeof SERVICE_STATUS_MESSAGES>;
