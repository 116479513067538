import { combineReducers } from '@reduxjs/toolkit';

import download, {
  defaultState as defaultDownloadState,
} from './reducer-download';
import healthCheck, {
  defaultState as defaultHealthState,
} from './reducer-health';
import print, { defaultState as defaultPrintState } from './reducer-print';
import topnav, { defaultState as defaultTopnavState } from './reducer-topnav';
import tour, { defaultState as defaultTourState } from './reducer-tour';
import whatsNew, {
  defaultState as defaultWhatsNewState,
} from './reducer-whatsnew';

export const defaultState = {
  healthCheck: defaultHealthState,
  print: defaultPrintState,
  download: defaultDownloadState,
  topnav: defaultTopnavState,
  tour: defaultTourState,
  whatsNew: defaultWhatsNewState,
};

export type AppState = typeof defaultState;

export default combineReducers({
  healthCheck,
  print,
  download,
  topnav,
  tour,
  whatsNew,
});
