import {
  type MouseEventHandler,
  type MouseEvent,
  type ReactNode,
  useCallback,
} from 'react';

import history from '@/history';
import { useAppSelector } from '@redux/hooks';
import { selectAccountContextFromAccount } from '@redux/reducers/reducer-auth';
import { getAccountContextForHistory } from '@routes/utils';

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  children: ReactNode;
  className?: string;
  useContext?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

function isLeftClickEvent(event: MouseEvent<HTMLAnchorElement>) {
  return event.button === 0;
}

function isModifiedEvent(event: MouseEvent<HTMLAnchorElement>) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const Link = ({
  useContext = true,
  to,
  children,
  onClick,
  ...props
}: LinkProps) => {
  const accountContext = useAppSelector(selectAccountContextFromAccount);

  const context = useContext ? getAccountContextForHistory(accountContext) : '';

  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        onClick(event);
      }

      if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
        return;
      }

      if (event.defaultPrevented === true) {
        return;
      }

      event.preventDefault();
      history?.push(`${context}${to}`);
    },
    [onClick, to, context],
  );

  return (
    <a href={`${context}${to}`} {...props} onClick={handleClick}>
      {children}
    </a>
  );
};

export default Link;
