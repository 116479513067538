import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import type { CreateEntityActionOptions } from './createEntityAction';
import getEntityObject from './getEntityObject';

const createStatusEntityReducers = <State, Actions>(
  entities: CreateEntityActionOptions<Actions>,
  builder: ActionReducerMapBuilder<State>,
) => {
  Object.keys(entities).forEach(entity => {
    const { thunk, stateKey, pending, rejected, fulfilled } =
      entities[entity as keyof Actions];

    builder
      .addCase(thunk.pending, (state, action) => {
        const [base, key] = getEntityObject(state, stateKey, action.meta.arg);
        const obj = key ? base[key] : base;
        obj.status = 'loading';
        pending?.(state, action);
      })
      .addCase(thunk.fulfilled, (state, action) => {
        const [base, key] = getEntityObject(state, stateKey, action.meta.arg);
        Object.assign(key ? base[key] : base, {
          status: 'ready',
          ...action.payload.body,
        });
        fulfilled?.(state, action);
      })
      .addCase(thunk.rejected, (state, action) => {
        const [base, key] = getEntityObject(state, stateKey, action.meta.arg);
        if (action.error.name !== 'AbortError') {
          Object.assign(key ? base[key] : base, {
            ...base[key],
            status: 'error',
            error: action.payload || action.error,
          });
          rejected?.(state, action);
        }
      });
  });
};

export default createStatusEntityReducers;
