import {
  createAsyncThunk,
  type AsyncThunk,
  type AsyncThunkAction,
} from '@reduxjs/toolkit';

import type {
  EntityActionParams,
  EntityServiceResponse,
  ServiceError,
} from '@models';
import type { AppDispatch, RootState } from '@redux/store';

type ThunkApiConfig = {
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: ServiceError;
};

const actionsInProgress = new Map<
  string,
  ReturnType<AsyncThunkAction<any, any, ThunkApiConfig>>
>();

const debounceAction = <
  Params extends EntityActionParams | void,
  Return extends EntityServiceResponse<any>,
>(
  thunk: AsyncThunk<Return, Params, ThunkApiConfig>,
): AsyncThunk<Return, Params, ThunkApiConfig> =>
  createAsyncThunk<Return, Params, ThunkApiConfig>(
    `${thunk.typePrefix}/debounceAction`,
    async (params, thunkAPI) => {
      const { dispatch } = thunkAPI;
      if (actionsInProgress.has(thunk.typePrefix)) {
        actionsInProgress.get(thunk.typePrefix)!.abort();
        actionsInProgress.delete(thunk.typePrefix);
      }
      const actionPromise = dispatch(thunk(params));
      actionsInProgress.set(thunk.typePrefix, actionPromise);

      return actionPromise.unwrap().finally(() => {
        if (actionsInProgress.get(thunk.typePrefix) === actionPromise) {
          actionsInProgress.delete(thunk.typePrefix);
        }
      });
    },
  );

export default debounceAction;
