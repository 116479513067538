import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Button, Image, List } from 'semantic-ui-react';

import type { StepsConfig } from '../tourConfig';

import s from './TourSteps.scss';

const stepStyles = {
  options: {
    width: '65rem',
  },
};

const robotEl = (
  <Image src="/img/robot_laptop.svg" className={s.robotLaptopImg} />
);

const Step1 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        The <strong>Policy Editor</strong> is where you can compose and
        associate the rules used to evaluate the compliance of your sources and
        images. The policy group shown in this view contains the following
        configurable elements:
      </p>
      <List bulleted>
        <List.Item>Rule Sets</List.Item>
        <List.Item>Allowlists</List.Item>
        <List.Item>Mappings</List.Item>
        <List.Item>Allowed / Denied Images</List.Item>
      </List>
      <p>In the next few steps we will go over what these items represent.</p>
    </div>
  );
};

const Step2 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      <h3>Rule Sets</h3>
      <p>
        The <strong>Rule Sets</strong> tab provides the list of discrete rule
        sets that each contain the criteria used to assert compliance. Rule Sets
        can be created for images or sources.
      </p>
      <p>
        Every rule in the rule set is composed of a gate selection (for example,{' '}
        <code>vulnerabilities</code>), a trigger (for example,{' '}
        <code>package</code>) and any additional parameters that may be required
        for the gate to evaluate the rule.
      </p>
      <p>
        Against every rule, an action can be assigned:{' '}
        <Button color="red" size="mini">
          STOP
        </Button>{' '}
        or{' '}
        <Button color="orange" size="mini">
          WARN
        </Button>{' '}
        or{' '}
        <Button color="green" size="mini">
          GO
        </Button>
        . Actions determine the outcome of an evaluation when the rule criteria
        is matched during image or source analysis.
      </p>
    </div>
  );
};

const Step3 = () => {
  useStyles(s);
  return (
    <div className={s.root}>
      <p>
        Add a new rule set by clicking this button. After you specify if this
        set is for images or sources you&#39;ll be presented with the rule
        editing dialog, where you can add one or many rules, each with{' '}
        <strong>Gate</strong> and <strong>Trigger</strong> details, and an
        associated action.
      </p>
    </div>
  );
};

const Step4 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      <h3>Allowlists</h3>
      <p>
        Sometimes you will want to exclude items from evaluation, and allowlists
        are where you can provide the details to do just that. Any evaluated
        item with a trigger ID can be added to an allowlist, and the lifespan of
        the exclusion can be (optionally) associated with each entry.
      </p>
      <p>
        Add a new allowlist set by clicking the{' '}
        <strong>Add New Allowlist</strong> button that is displayed when the{' '}
        <strong>Allowlists</strong> tab is selected.
      </p>
    </div>
  );
};

const Step5 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      <h3>Mappings</h3>
      <p>
        Mappings are schema definitions that associate rule sets and allowlists
        with sources or images. These associations can be broad (using an
        asterisk wildcard) or specific to named registries, repositories, and,
        in the case of images, tags. A mapping can include one or more rule sets
        and (optionally) zero or more allowlists.
      </p>
      <p>
        Mappings are evaluated in top-down order, and the first match is used to
        determine the compliance status of the source or image.
      </p>
    </div>
  );
};

const Step6 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      <h3>Allowed / Denied Images</h3>
      <p>
        During analysis, images can be allowed or denied based on the criteria
        provided by the items under the <strong>Allowed Images</strong> and{' '}
        <strong>Denied Images</strong> sub-tabs shown within this tab. Allowed
        images are always considered compliant, while denied images are always
        considered non-compliant, regardless of the evaluation of any matched
        rule sets.
      </p>
    </div>
  );
};

export default (): StepsConfig => ({
  id: 'policy',
  steps: [
    {
      styles: stepStyles,
      content: <Step1 />,
      placement: 'center',
      target: 'body',
    },
    {
      styles: stepStyles,
      content: <Step2 />,
      target: '.ui.pointing.menu a:nth-child(1)',
    },
    {
      content: <Step3 />,
      target: '#new-policy-item-modal-open',
    },
    {
      styles: stepStyles,
      content: <Step4 />,
      target: '.ui.pointing.menu a:nth-child(2)',
    },
    {
      styles: stepStyles,
      content: <Step5 />,
      target: '.ui.pointing.menu a:nth-child(3)',
    },
    {
      styles: stepStyles,
      content: <Step6 />,
      target: '.ui.pointing.menu a:nth-child(4)',
    },
  ],
});
