import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Image } from 'semantic-ui-react';

import type { StepsConfig } from '../tourConfig';

import s from './TourSteps.scss';

const stepStyles = {
  options: {
    width: '65rem',
  },
};

const robotEl = (
  <Image src="/img/robot_report.svg" className={s.robotReportImg} />
);

const Step1 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        Anchore provides a powerful reporting system which can be used to
        generate customized outputs in human or machine-readable format. Common
        reports use-cases include generating a list of images failing a policy
        check or showing the compliance status of a runtime environment.
      </p>
    </div>
  );
};

const Step2 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        Use one of the existing templates or customize your own template with
        your preferred fields. Once you have selected the template, add the
        filters to scope the data you want generated and then optionally set a
        schedule for ongoing generation.
      </p>
    </div>
  );
};

const Step3 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        If you expect to generate a large number of ongoing reports, users are
        advised to configure the appropriate data retention policies.
      </p>
    </div>
  );
};

export default (): StepsConfig => ({
  id: 'reports',
  data: {
    overrideTourPropsOnClose: {
      '/reports': {
        isDisabled: true,
        showIntro: true,
      },
      '/reports/new': {
        isDisabled: true,
        showIntro: true,
      },
    },
  },
  steps: [
    {
      styles: stepStyles,
      content: <Step1 />,
      placement: 'center',
      target: 'body',
    },
    {
      styles: stepStyles,
      content: <Step2 />,
      placement: 'center',
      target: 'body',
    },
    {
      styles: stepStyles,
      content: <Step3 />,
      placement: 'center',
      target: 'body',
    },
  ],
});
