// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yHzmX,.msiCl{align-items:center;display:flex;margin:0 .5rem!important;overflow:hidden}.yHzmX .C_0VP,.msiCl .C_0VP{align-items:center;background-color:#fff;color:#000000de;display:flex!important;float:right;height:30px;height:1.875rem;justify-content:center;line-height:10.4px!important;line-height:.65rem!important;margin:-.5rem -.75rem -.5rem .5rem;opacity:1!important;padding:0 .5rem}.yHzmX{margin:0 0 0 .5rem!important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rangeLabel": `yHzmX`,
	"versionLabel": `msiCl`,
	"versionLabelDetail": `C_0VP`
};
module.exports = ___CSS_LOADER_EXPORT___;
