import type { TupleToUnion } from 'type-fest';

export const SBOM_FORMATS = ['spdx', 'syft', 'cdx', 'other'] as const;

export type SBOMFormat = TupleToUnion<typeof SBOM_FORMATS>;

export const sbomFormatLabels: Record<SBOMFormat, string> = {
  spdx: 'SPDX',
  syft: 'Syft',
  cdx: 'CycloneDX',
  other: 'Other',
};

export const SBOM_SCHEMAS = ['json', 'xml', 'yaml', 'other', 'spdx'] as const;

export type SBOMSchema = TupleToUnion<typeof SBOM_SCHEMAS>;

export const sbomSchemaLabels: Record<SBOMSchema, string> = {
  json: 'JSON',
  spdx: 'SPDX',
  xml: 'XML',
  yaml: 'YAML',
  other: 'Other',
};
