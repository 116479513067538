import h from 'animate.css';
import useStyles from 'isomorphic-style-loader/useStyles';
import c from 'react-datepicker/dist/react-datepicker.css';
import n from 'react-toastify/dist/ReactToastify.css';
import o from 'semantic-ui-css/semantic.min.css';

import e from '@/../public/font-mfizz/font-mfizz.css';
import i from '@/../public/pace/pace-theme-minimal.css';
import a from '@/../public/pdf/roboto.css';
import u from '@/../public/prism/prism.css';
import r from '@/../public/react-table/react-table.css';

export const useGlobalStyles = () => useStyles(a, n, c, h, o, r, e, u, i);

export default useGlobalStyles;
