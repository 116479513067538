import type { Reducer, AnyAction } from 'redux';

import type { RootState } from '@redux/store';

import {
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
  SET_LOGOUT_PENDING,
  SET_LOGOUT_SUCCESS,
  SET_LOGOUT_ERROR,
} from '../actionTypes';

export interface AuthAccountState {
  authType: string;
  sessionStart: string;
  sessionID: string;
  username: string;
  authUsername: string;
  accountname: string;
  userGroups: any[];
  isEnabled: boolean;
  isAdmin: boolean;
  isAdminAccount: boolean;
  userCreatedAt: string;
  userLastUpdated: string;
  context?: {
    accountname: string;
    namespace: string;
    isEnabled: boolean;
  };
  isAddRepositoriesEnabled: boolean;
  rbac: {
    account: string;
    permissions: {
      action: string;
      target: string;
      from: string[];
    }[];
    roles: {
      name: string;
      description: string;
      immutable: boolean;
      created_at: string;
    }[];
  }[];
  accounts: {
    name: string;
    email: string | null;
    state: string;
    type: string;
    created_at: string;
    last_updated: string;
  }[];
}

export interface AuthState {
  loginError: boolean;
  logoutError: boolean;
  isAuthenticated: boolean;
  isFetching: boolean;
  errorMsg: any;
  prevAuthState: boolean;
  engineVersion: string | false;
  enterpriseCommitSha: string | null;
  imageBuildTimestamp: string | null;
  dbVersion: string | false;
  uiVersion: string | false;
  permissions: Record<string, boolean> | false;
  account: AuthAccountState | false;
}

export const defaultState: Readonly<AuthState> = Object.freeze({
  loginError: false,
  logoutError: false,
  isAuthenticated: false,
  isFetching: false,
  errorMsg: false,
  prevAuthState: false,
  engineVersion: false,
  enterpriseCommitSha: null,
  imageBuildTimestamp: null,
  dbVersion: false,
  uiVersion: false,
  permissions: false,
  account: false,
});

// Return undefined when account is false so we can use optional chaining
export const selectAccount = (state: RootState) =>
  state.auth.account ? state.auth.account : undefined;
export const selectAccountContextFromAccount = (state: RootState) =>
  selectAccount(state)?.context;
export const selectIsAddRepositoriesEnabledFromAccount = (state: RootState) =>
  selectAccount(state)?.isAddRepositoriesEnabled;
export const selectIsAdminFromAccount = (state: RootState) =>
  selectAccount(state)?.isAdmin;

const reducer: Reducer<AuthState> = (
  state = defaultState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_LOGIN_PENDING:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: true,
      };
    case SET_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        loginError: false,
        errorMsg: false,
        dbVersion: action.dbVersion,
        engineVersion: action.engineVersion,
        enterpriseCommitSha: action.enterpriseCommitSha,
        imageBuildTimestamp: action.imageBuildTimestamp,
        uiVersion: action.uiVersion,
        prevAuthState: state.isAuthenticated,
        permissions: action.permissions,
        account: action.account,
      };
    case SET_LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: false,
        loginError: true,
        errorMsg: action.errorMsg,
      };

    case SET_LOGOUT_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case SET_LOGOUT_SUCCESS:
      return {
        ...state,
        ...defaultState,
        prevAuthState: state.isAuthenticated,
      };
    case SET_LOGOUT_ERROR:
      return {
        ...state,
        isFetching: false,
        logoutError: true,
        errorMsg: action.errorMsg,
      };

    default:
      return state;
  }
};

export default reducer;
