import type { EntityAdapter, EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';
import type { EntitySelectors } from '@reduxjs/toolkit/src/entities/models';

import type { AsyncState, WithAsyncState } from '@models';

import type { AsyncAdapter, AsyncSelectors } from './createAsyncAdapter';
import { createAsyncAdapter } from './createAsyncAdapter';

export type AsyncEntityAdapterOptions<T> = Parameters<
  typeof createEntityAdapter<T>
>[0] & {
  // custom options
};

export type AsyncEntityState<T> = WithAsyncState<EntityState<T>>;

export type AsyncEntityAdapter<T> = Omit<
  AsyncAdapter & EntityAdapter<T>,
  'getInitialState' | 'getSelectors'
> & {
  getInitialState(): AsyncEntityState<T>;
  getInitialState<S>(state: S): AsyncEntityState<T> & S;
  getSelectors(): AsyncSelectors<AsyncState> &
    EntitySelectors<T, EntityState<T>>;
  getSelectors<S>(
    selectState: (state: S) => AsyncEntityState<T>,
  ): AsyncSelectors<S> & EntitySelectors<T, S>;
};

/**
 * Convenience wrapper around RTK `createEntityAdapter` that adds async state.
 *
 * @see createEntityAdapter
 */
export const createAsyncEntityAdapter = <T>(
  options: AsyncEntityAdapterOptions<T> = {},
): AsyncEntityAdapter<T> => {
  const { ...adapterOptions } = options;
  const entityAdapter = createEntityAdapter<T>(adapterOptions);
  const asyncAdapter = createAsyncAdapter();

  const getInitialState = (state: unknown = {}) =>
    entityAdapter.getInitialState(asyncAdapter.getInitialState(state));

  const getSelectors = (<S>(
    selectState?: (state: S) => AsyncEntityState<T>,
  ) => {
    // TypeScript is fussy about arg type here due to the overloads
    if (selectState) {
      return {
        ...entityAdapter.getSelectors(selectState),
        ...asyncAdapter.getSelectors(selectState),
      };
    }

    return {
      ...entityAdapter.getSelectors(),
      ...asyncAdapter.getSelectors(),
    };
  }) as AsyncEntityAdapter<T>['getSelectors'];

  return {
    ...entityAdapter,
    ...asyncAdapter,
    getInitialState,
    getSelectors,
  };
};

export default createAsyncEntityAdapter;
