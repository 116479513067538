import type { ReactNode } from 'react';

// Wrapper component that conditionally wraps children in a component
const ConditionalWrap = ({
  condition,
  wrap,
  children,
}: {
  condition: boolean;
  wrap: (c: ReactNode) => ReactNode;
  children: ReactNode;
}) => <>{condition ? wrap(children) : children}</>;

export default ConditionalWrap;
