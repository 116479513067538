// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ubl0i{max-height:90vh}.ubl0i.vvnEO{text-align:left}.ubl0i .oFiAX{margin-bottom:2rem}.ubl0i .oFiAX .GMUE5{color:#ff880b;display:inline-block}.ubl0i .GhzJb{width:480px;width:30rem}.ubl0i .L0LiB{margin-left:2rem;top:-52px;top:-3.25rem;width:144px;width:9rem}.ubl0i .L0LiB,.ubl0i .YUGll{background-repeat:no-repeat;background-size:contain;height:128px;height:8rem;position:absolute}.ubl0i .YUGll{left:32px;left:2rem;top:-80px;top:-5rem;width:96px;width:6rem}.ubl0i .aYoEm{background-repeat:no-repeat;background-size:contain;height:128px;height:8rem;left:-8px;left:-.5rem;position:absolute;top:-72px;top:-4.5rem;width:144px;width:9rem}.ubl0i .SL865{width:144px;width:9rem}.ubl0i .kVZN1,.ubl0i .SL865{background-repeat:no-repeat;background-size:contain;height:128px;height:8rem;left:32px;left:2rem;position:absolute;top:-80px;top:-5rem}.ubl0i .kVZN1{width:80px;width:5rem}.ubl0i .n384N{width:80px;width:5rem}.ubl0i .n384N,.ubl0i .raBPC{background-repeat:no-repeat;background-size:contain;height:128px;height:8rem;left:32px;left:2rem;position:absolute;top:-80px;top:-5rem}.ubl0i .raBPC{width:128px;width:8rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ubl0i`,
	"alignLeft": `vvnEO`,
	"welcomeHeader": `oFiAX`,
	"version": `GMUE5`,
	"robotSaucerImg": `GhzJb`,
	"robotBoxImg": `L0LiB`,
	"robotJuggleImg": `YUGll`,
	"robotShrugImg": `aYoEm`,
	"robotTelescopeImg": `SL865`,
	"robotLaptopImg": `kVZN1`,
	"robotReadingImg": `n384N`,
	"robotReportImg": `raBPC`
};
module.exports = ___CSS_LOADER_EXPORT___;
