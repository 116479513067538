import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Image, List } from 'semantic-ui-react';

import type { StepsConfig } from '../tourConfig';

import s from './TourSteps.scss';

const stepStyles = {
  options: {
    width: '65rem',
  },
};

const robotEl = (
  <Image src="/img/robot_shrug.svg" className={s.robotShrugImg} />
);

const Step1 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        The <strong>Images</strong> view allows you to view the SBOMs and their
        associated vulnerability and policy results for images that have been
        scanned by Anchore. All SBOMs are sorted via their registry and tag
        metadata, independent of whether they were sourced from CI/CD, a
        registry, or a runtime environment.
      </p>
    </div>
  );
};

const Step2 = () => {
  useStyles(s);
  return (
    <div className={s.root}>
      <p>
        You can also analyze individual tags or specific image SHAs by clicking
        the <strong>Analyze Tag</strong> button, shown above.
      </p>
    </div>
  );
};

const Step3 = () => {
  useStyles(s);
  return (
    <div className={s.root}>
      <p>
        To add an entire image repository, click on the&nbsp;
        <strong>Analyze Repository</strong> button, shown above. All the tags in
        the repository—including those added subsequently—will be analyzed,
        unless you specify otherwise.
      </p>
    </div>
  );
};

const Step4 = () => {
  useStyles(s);
  return (
    <div className={s.root}>
      <p>
        The status of all images under analysis, including those that are unable
        to be scanned, will be listed within the&nbsp;
        <strong>Image Analysis Status</strong> dialog, which you can view by
        clicking here.
      </p>
    </div>
  );
};

const Step5 = () => {
  useStyles(s);
  return (
    <div className={s.root}>
      <p>
        Added items appear in this table, which you can use to navigate through
        registries, repositories, and tags, in order to view specific images and
        then view their compliance and vulnerability evaluations.
      </p>
    </div>
  );
};

const Step6 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        Images can be added to the system in other ways too. To add an image:
      </p>
      <List bulleted>
        <List.Item>
          Via CI/CD with AnchoreCTL, please read the&nbsp;
          <a
            href="https://docs.anchore.com/current/docs/integration/ci_cd/"
            target="_blank"
            rel="noreferrer"
          >
            CI/CD integration documentation
          </a>
        </List.Item>
        <List.Item>
          Via Kubernetes, please read the&nbsp;
          <a
            href="https://docs.anchore.com/current/docs/integration/kubernetes/"
            target="_blank"
            rel="noreferrer"
          >
            Kubernetes integration documentation
          </a>
        </List.Item>
        <List.Item>
          Via AWS ECS, please read the&nbsp;
          <a
            href="https://docs.anchore.com/current/docs/integration/ecs/"
            target="_blank"
            rel="noreferrer"
          >
            ECS integration documentation
          </a>
        </List.Item>
      </List>
    </div>
  );
};

export default (): StepsConfig => ({
  id: 'image',
  steps: [
    {
      styles: stepStyles,
      content: <Step1 />,
      placement: 'center',
      target: 'body',
    },
    {
      content: <Step2 />,
      target: '#analyze_repository',
    },
    {
      content: <Step3 />,
      target: '#analyze_tag',
    },
    {
      content: <Step4 />,
      target: '.ui.orange.small.basic.icon.right.labeled.button',
    },
    {
      content: <Step5 />,
      target: '.ReactTable',
    },
    {
      styles: stepStyles,
      content: <Step6 />,
      target: 'body',
      placement: 'center',
    },
  ],
});
