import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import type { AsyncState, RestVerb } from '@models';

import type {
  AsyncPendingStatus,
  AsyncStateAdapter,
} from './createAsyncAdapter';
import type { CreateEntityActionOptions } from './createEntityAction';

const asyncStatusByVerb: Record<RestVerb, AsyncPendingStatus> = {
  get: 'loading',
  post: 'creating',
  patch: 'updating',
  put: 'updating',
  delete: 'deleting',
};

/**
 * Creates extra reducers for async entities. The case reducers added by this
 * helper are only concerned with updating the async loading state, not the data
 * itself - This should be handled via the optional callbacks on async action
 * creators, which are invoked here if present.
 */
export const createAsyncReducers = <State extends AsyncState, Actions>(
  builder: ActionReducerMapBuilder<State>,
  entities: CreateEntityActionOptions<Actions>,
  adapter: AsyncStateAdapter,
) => {
  Object.keys(entities).forEach(entity => {
    const { thunk, verb, pending, rejected, fulfilled } =
      entities[entity as keyof Actions];

    builder
      .addCase(thunk.pending, (state, action) => {
        adapter.setPending(state, asyncStatusByVerb[verb]);
        pending?.(state, action);
      })

      .addCase(thunk.fulfilled, (state, action) => {
        adapter.setFulfilled(state);
        fulfilled?.(state, action);
      })

      .addCase(thunk.rejected, (state, action) => {
        if (action.error.name === 'AbortError') {
          adapter.setFulfilled(state);
          return;
        }

        adapter.setRejected(state, action.payload || action.error);
        rejected?.(state, action);
      });
  });
};

export default createAsyncReducers;
