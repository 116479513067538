import type { CamelCasedPropertiesDeep } from 'type-fest';

import type {
  SystemResourceLimit as RawSystemResourceLimit,
  SystemResourceLimitsList as RawSystemResourceLimitsList,
} from '@/api/api';

import type {
  EntityActionParams,
  EntityServiceResponse,
} from './common.models';

export { RawSystemResourceLimit, RawSystemResourceLimitsList };

export type SystemResourceLimit =
  CamelCasedPropertiesDeep<RawSystemResourceLimit>;

export type GetLimitsActionParams = EntityActionParams | undefined;
export type GetLimitsResponse = EntityServiceResponse<SystemResourceLimit[]>;

export type SystemLimitType = Required<SystemResourceLimit>['name'];
export const systemLimitDisplayNames = {
  sbom_inventory: 'SBOMs',
  account_inventory: 'Accounts',
  report_execution_inventory: 'Report Executions',
  report_inventory: 'Reports',
  user_inventory: 'Users',
} satisfies Record<SystemLimitType, string>;
