import { Provider } from 'react-redux';

import buildStore from '@redux/store';
import Layout from '@components/Layout';
import Login from '@components/Login';

import arePermissionsGoodForRoute from './arePermsGoodForRoute';

const store = buildStore(__DEV__);

export default Component => props => {
  const {
    isAuthenticated,
    isEngineVersionGood,
    dbVersion,
    engineVersion,
    enterpriseCommitSha,
    imageBuildTimestamp,
    uiVersion,
    isEngineHealthy,
    isLicenseGood,
    route,
    params,
    account,
    isReportsEnabledAndActive,
    getUserPerms,
    isLdapEnabled,
    isSsoAuthOnly,
    isAppDBHealthy,
    pathname,
    apolloClient,
    apiUrl,
    query,
    customLinks,
    baseUrl,
    whatsNew,
    isWebSocketOnly,
    enrichInventoryView,
  } = props;

  const { parent, path } = route;
  const componentContext = !parent?.path ? route : parent;
  const { actions, navbar = {}, redirect } = componentContext;
  const { title = 'Page Not Found - Anchore', name = 'not-found' } = navbar;

  return getUserPerms({ account, actions })
    .then(permissions => ({
      chunks: [name],
      title,
      component: (
        <Provider store={store}>
          <Layout
            store={store}
            isEngineVersionGood={isEngineVersionGood}
            isAuthenticated={isAuthenticated}
            dbVersion={dbVersion}
            engineVersion={engineVersion}
            enterpriseCommitSha={enterpriseCommitSha}
            imageBuildTimestamp={imageBuildTimestamp}
            uiVersion={uiVersion}
            isEngineHealthy={isEngineHealthy}
            isLicenseGood={isLicenseGood}
            isReportsEnabledAndActive={isReportsEnabledAndActive}
            account={account}
            permissions={isAuthenticated ? permissions : false}
            isAppDBHealthy={isAppDBHealthy}
            apolloClient={apolloClient}
            pathname={pathname}
            apiUrl={apiUrl}
            customLinks={customLinks}
            routeMask={baseUrl + path}
            whatsNew={whatsNew}
            isWebSocketOnly={isWebSocketOnly}
            enrichInventoryView={enrichInventoryView}
          >
            <Component
              title={title}
              params={params}
              pathname={pathname}
              baseUrl={baseUrl}
              redirect={redirect}
              query={query}
              isAuthenticated={isAuthenticated}
              permissions={permissions}
              routePermission={
                isAuthenticated
                  ? arePermissionsGoodForRoute({ pathname, permissions })
                  : false
              }
              enrichInventoryView={enrichInventoryView}
            >
              <Login
                actions={actions}
                isLdapEnabled={isLdapEnabled}
                isSsoAuthOnly={isSsoAuthOnly}
              />
            </Component>
          </Layout>
        </Provider>
      ),
    }))
    .catch(err => {
      throw err;
    });
};
