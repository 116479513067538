import type { Step } from 'react-joyride';

import type { TourPathProps, TourState } from '@redux/reducers/reducer-tour';

import applicationSteps from './TourSteps/applicationSteps';
import imageSteps from './TourSteps/imageSteps';
import kubernetesSteps from './TourSteps/kubernetesSteps';
import policyEditorSteps from './TourSteps/policyEditorSteps';
import policyManagerSteps from './TourSteps/policyManagerSteps';
import reportsSteps from './TourSteps/reportsSteps';
import welcomeSteps from './TourSteps/welcomeSteps';

export type TourPathPropsItem = Partial<
  Record<keyof TourState['tourProps'], TourPathProps>
>;

export interface StepsConfig {
  id: string;
  steps: Step[];
  data?: {
    overrideTourPropsOnClose?: TourPathPropsItem;
  };
  adminOnly?: boolean;
}

export interface TourConfig {
  '/*': StepsConfig[];
  '/applications': StepsConfig[];
  '/artifacts/image': StepsConfig[];
  '/inventory/kubernetes': StepsConfig[];
  '/policy': StepsConfig[];
  '/policy/:policyId': StepsConfig[];
  '/reports': StepsConfig[];
  '/reports/new': StepsConfig[];
}

export default (): TourConfig => ({
  '/*': [welcomeSteps()],
  '/policy': [policyManagerSteps()],
  '/policy/:policyId': [policyEditorSteps()],
  '/applications': [applicationSteps()],
  '/inventory/kubernetes': [kubernetesSteps()],
  '/artifacts/image': [imageSteps()],
  '/reports': [reportsSteps()],
  '/reports/new': [reportsSteps()],
});
