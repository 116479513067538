import type { EntityState } from '@reduxjs/toolkit';
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import type {
  SBOMGroupFormValues,
  GetSBOMGroupResponse,
  GetSBOMGroupsResponse,
  EntityServiceResponse,
  UpdateSBOMGroupMembersRequestBody,
  GetSBOMGroupsThunkParams,
  SBOMGroupMembers,
  SBOMGroup,
  SBOMGroupWithDetail,
  CreateSBOMGroupResponse,
  UpdateSBOMGroupMembersResponse,
} from '@models';
import { deleteSBOMById, fetchSBOMById } from '@redux/sboms.slice';
import type { RootState } from '@redux/store';
import { setViewState } from '@redux/views.slice';

import type { AsyncEntityState } from './common';
import {
  createAsyncEntityAdapter,
  createAsyncReducers,
  createEntityAction,
  getEntityActions,
} from './common';

const sliceName = 'sbomGroups';

const sbomGroupsAdapter = createAsyncEntityAdapter<SBOMGroup>({
  selectId: ({ uuid }) => uuid,
  sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
});

const sbomGroupMembersAdapter = createEntityAdapter<SBOMGroupMembers>({
  selectId: ({ uuid }) => uuid,
});

const sbomGroupMembersSelectors = sbomGroupMembersAdapter.getSelectors();

export interface SBOMGroupsState extends AsyncEntityState<SBOMGroup> {
  members: EntityState<SBOMGroupMembers>;
}

const entities = {
  fetchSBOMGroups: createEntityAction<
    GetSBOMGroupsThunkParams,
    GetSBOMGroupsResponse,
    SBOMGroupsState
  >({
    actionName: 'sbomGroups/getList',
    servicePath: '/service/sbom-groups',
    customRequest: (request, path, params) => request.get(path).query(params),
    onSuccess: ({ totalRows }, _, { dispatch }) => {
      dispatch(setViewState('sbomGroupsPagination', { totalRows }));
    },
    reducerOptions: {
      fulfilled: (state, action) => {
        const { page } = action.meta.arg;
        const { groups, membersList } = action.payload.body.data;

        // Treat the first page as a reset and replace the store, otherwise merge new data
        const method = page === 1 ? 'setAll' : 'upsertMany';

        sbomGroupsAdapter[method](state, groups);
        sbomGroupMembersAdapter[method](state.members, membersList);
      },
    },
  }),
  fetchSBOMGroupById: createEntityAction<
    { groupId: string },
    GetSBOMGroupResponse,
    SBOMGroupsState
  >({
    actionName: 'sbomGroups/getById',
    servicePath: '/service/sbom-groups/:groupId',
    reducerOptions: {
      fulfilled: (state, action) => {
        const { group, members } = action.payload.body.data;

        sbomGroupsAdapter.upsertOne(state, group);
        sbomGroupMembersAdapter.upsertOne(state.members, members);
      },
    },
  }),
  createSBOMGroup: createEntityAction<
    SBOMGroupFormValues,
    CreateSBOMGroupResponse,
    SBOMGroupsState
  >({
    actionName: 'sbomGroups/create',
    servicePath: '/service/sbom-groups',
    verb: 'post',
    reducerOptions: {
      fulfilled: (state, action) => {
        const { group, members } = action.payload.body.data;

        sbomGroupsAdapter.addOne(state, group);
        sbomGroupMembersAdapter.addOne(state.members, members);
      },
    },
  }),
  deleteSBOMGroupById: createEntityAction<
    { groupId: string },
    EntityServiceResponse<unknown>,
    SBOMGroupsState
  >({
    actionName: 'sbomGroups/deleteById',
    servicePath: '/service/sbom-groups/:groupId',
    verb: 'delete',
    reducerOptions: {
      fulfilled: (state, action) => {
        sbomGroupsAdapter.removeOne(state, action.meta.arg.groupId);
        sbomGroupMembersAdapter.removeOne(
          state.members,
          action.meta.arg.groupId,
        );
      },
    },
  }),
  deleteSBOMGroups: createEntityAction<
    { groupIds: string[] },
    EntityServiceResponse<unknown>,
    SBOMGroupsState
  >({
    actionName: 'sbomGroups/deleteMany',
    servicePath: '/service/sbom-groups',
    customRequest: (request, path, { groupIds }) =>
      request.delete(path).send({ ids: groupIds }),
    verb: 'delete',
    reducerOptions: {
      fulfilled: (state, action) => {
        sbomGroupsAdapter.removeMany(state, action.meta.arg.groupIds);
        sbomGroupMembersAdapter.removeMany(
          state.members,
          action.meta.arg.groupIds,
        );
      },
    },
  }),
  updateSBOMGroupMembers: createEntityAction<
    UpdateSBOMGroupMembersRequestBody,
    UpdateSBOMGroupMembersResponse,
    SBOMGroupsState
  >({
    actionName: 'sbomGroups/updateMembers',
    servicePath: '/service/sbom-groups',
    verb: 'patch',
    reducerOptions: {
      fulfilled: (state, action) => {
        const { groups, membersList } = action.payload.body.data;

        sbomGroupsAdapter.upsertMany(state, groups);
        sbomGroupMembersAdapter.upsertMany(state.members, membersList);
      },
    },
  }),
  removeAllSBOMGroupMembers: createEntityAction<
    { groupId: string },
    EntityServiceResponse<SBOMGroupWithDetail>,
    SBOMGroupsState
  >({
    actionName: 'sbomGroups/removeAllMembers',
    servicePath: '/service/sbom-groups/:groupId',
    verb: 'patch',
    onSuccess: (__, _, { dispatch }) => {
      dispatch(
        setViewState('sbomGroupSBOMsPagination', {
          page: 0,
          pageIds: {},
          totalRows: 0,
        }),
      );
    },
    reducerOptions: {
      fulfilled: (state, action) => {
        const group = action.payload.body.data;

        sbomGroupsAdapter.upsertOne(state, group);
        sbomGroupMembersAdapter.upsertOne(state.members, {
          uuid: group.uuid,
          sbomIds: [],
        });
      },
    },
  }),
};

export const {
  fetchSBOMGroups,
  fetchSBOMGroupById,
  createSBOMGroup,
  deleteSBOMGroupById,
  deleteSBOMGroups,
  updateSBOMGroupMembers,
  removeAllSBOMGroupMembers,
} = getEntityActions(entities);

export const initialState: SBOMGroupsState = sbomGroupsAdapter.getInitialState({
  members: sbomGroupMembersAdapter.getInitialState(),
});

export const sbomGroupsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    createAsyncReducers(builder, entities, sbomGroupsAdapter);

    builder.addCase(fetchSBOMById.fulfilled, (state, action) => {
      const { sbomId } = action.meta.arg;
      const { groups } = action.payload.body.data;
      const groupIds = groups.map(group => group.uuid);

      // Sync all Group memberships
      const updates = sbomGroupMembersSelectors
        .selectAll(state.members)
        .filter(
          member =>
            groupIds.includes(member.uuid) && !member.sbomIds.includes(sbomId),
        )
        .map(member => ({
          id: member.uuid,
          changes: { sbomIds: [...member.sbomIds, sbomId] },
        }));

      sbomGroupMembersAdapter.updateMany(state.members, updates);
    });

    builder.addCase(deleteSBOMById.fulfilled, (state, action) => {
      const { sbomId } = action.meta.arg;

      // Remove the SBOM ID from all Group memberships
      const updates = sbomGroupMembersSelectors
        .selectAll(state.members)
        .filter(member => member.sbomIds.includes(sbomId))
        .map(member => ({
          id: member.uuid,
          changes: { sbomIds: member.sbomIds.filter(id => id !== sbomId) },
        }));

      sbomGroupMembersAdapter.updateMany(state.members, updates);
    });
  },
});

const globalizedSelectors = sbomGroupsAdapter.getSelectors<RootState>(
  state => state.sbomGroups,
);

const globalizedMembersSelectors =
  sbomGroupMembersAdapter.getSelectors<RootState>(
    state => state.sbomGroups.members,
  );

const selectMany = createSelector(
  [
    globalizedSelectors.selectAll,
    (_: RootState, groupIds: string[]) => groupIds,
  ],
  (groups, groupIds) => groups.filter(group => groupIds.includes(group.uuid)),
);

const selectPagination = (state: RootState) => state.views.sbomGroupsPagination;

export const sbomGroupsSelectors = {
  ...globalizedSelectors,
  members: globalizedMembersSelectors,
  selectMany,
  selectPagination,
};

export default sbomGroupsSlice.reducer;
