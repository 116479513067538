import { createSelector, createSlice } from '@reduxjs/toolkit';
import { useCallback } from 'react';

import type {
  FeedsHealth,
  HealthStatusBySystemComponent,
  Integration,
  ServicesHealth,
  StatusEntity,
  GetIntegrationsResponse,
  FetchIntegrationsActionParams,
} from '@models';
import { nullStatusEntity } from '@models';
import {
  createEntityAction,
  createStatusEntityReducers,
  getEntityActions,
} from '@redux/common';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import type { RootState } from '@redux/store';
import {
  createFeedsHealth,
  createServicesHealth,
  getFeedsHealth,
  getFeedsStatus,
  getServicesHealth,
  getServicesStatus,
  getSystemStatus,
} from '@utils/system';

import { fetchFeeds } from './feeds.slice';
import { fetchServices } from './services.slice';

export interface SystemHealthState {
  /**
   * Health statuses of core system components
   */
  statusByComponent: HealthStatusBySystemComponent;
  /**
   * Detailed health information for Services
   */
  services: ServicesHealth;
  /**
   * Detailed health information for Feeds
   */
  feeds: FeedsHealth;
  /**
   * Detailed metadata and health information for Integrations
   */
  integrations: StatusEntity<Record<string, Integration>>;
}

const entities = {
  fetchIntegrations: createEntityAction<
    FetchIntegrationsActionParams,
    GetIntegrationsResponse
  >({
    stateKey: 'integrations',
    servicePath: () => '/service/system/integrations',
    customRequest: (request, path, params) => request.get(path).query(params),
  }),
};

export const { fetchIntegrations } = getEntityActions(entities);

export const initialState: SystemHealthState = {
  statusByComponent: {
    feeds: undefined,
    services: undefined,
  },
  services: createServicesHealth(),
  feeds: createFeedsHealth(),
  integrations: nullStatusEntity,
};

const healthSlice = createSlice({
  name: 'health',
  initialState,
  reducers: {},
  extraReducers: builder => {
    createStatusEntityReducers(entities, builder);

    builder
      .addCase(fetchFeeds.fulfilled, (state, action) => {
        const feedsHealth = getFeedsHealth(action.payload.body.data);

        state.statusByComponent.feeds = getFeedsStatus(feedsHealth);
        state.feeds = feedsHealth;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        const servicesHealth = getServicesHealth(action.payload.body.data);

        state.statusByComponent.services = getServicesStatus(servicesHealth);
        state.services = servicesHealth;
      });
  },
});

export const selectStatuses = (state: RootState) =>
  state.health.statusByComponent;

export const selectServicesHealth = (state: RootState) => state.health.services;

export const selectFeedsHealth = (state: RootState) => state.health.feeds;

/**
 * Derives the overall system status based on statuses of individual components.
 * This selector will only update consumers when the status map changes, to
 * prevent unnecessary re-renders when the health details update, which is quite
 * chatty due to the bgCycler.
 */
export const selectSystemStatus = createSelector(
  selectStatuses,
  statusByComponent => getSystemStatus(statusByComponent),
);

export const useSystemStatus = () => useAppSelector(selectSystemStatus);

export const useSystemHealth = () => {
  const dispatch = useAppDispatch();
  const status = useSystemStatus();
  const statusByComponent = useAppSelector(selectStatuses);

  const isFetchingHealthData = useAppSelector(state =>
    [state.services.status, state.feeds.status].includes('loading'),
  );
  const isFetchingIntegrations = useAppSelector(
    state => state.health.integrations.status === 'loading',
  );

  /**
   * Re-fetches the latest data used to determine the system's health status.
   */
  const updateHealthData = useCallback(() => {
    if (isFetchingHealthData) return;

    dispatch(fetchFeeds());
    dispatch(fetchServices());
  }, []);

  /**
   * Re-fetches the list of integrations
   */
  const updateIntegrations = useCallback((onlyDegraded: boolean) => {
    if (isFetchingIntegrations) return;

    dispatch(fetchIntegrations({ onlyDegraded }));
  }, []);

  return {
    status,
    statusByComponent,
    updateHealthData,
    isFetchingHealthData,
    updateIntegrations,
    isFetchingIntegrations,
  };
};

export default healthSlice.reducer;
