import type { Reducer } from 'redux';

import type {
  AccountList,
  RegistryConfiguration,
  RolesList,
  TableViewProps,
  UserAcctRolesType,
  UserGroupMembership,
} from '@models';

import {
  SET_SYSTEM_ACTIVE_TAB,
  SET_SYSTEM_VIEW_PROPERTIES,
  SET_SERVICE_VIEW_MODAL,
  SET_FEED_VIEW_MODAL,
  SET_LDAP_CONFIG_FETCH_PENDING,
  SET_LDAP_CONFIG_FETCH_SUCCESS,
  SET_LDAP_CONFIG_FETCH_ERROR,
  SET_LDAP_CONFIG_UPDATE_PENDING,
  SET_LDAP_CONFIG_UPDATE_SUCCESS,
  SET_LDAP_CONFIG_UPDATE_ERROR,
  SET_LDAP_CONFIG_TEST_PENDING,
  SET_LDAP_CONFIG_TEST_SUCCESS,
  SET_LDAP_CONFIG_TEST_ERROR,
  SET_REGISTRIES_FETCH_PENDING,
  SET_REGISTRIES_FETCH_SUCCESS,
  SET_REGISTRIES_FETCH_ERROR,
  SET_REGISTRY_ADD_DIALOG,
  SET_REGISTRY_ADD_PENDING,
  SET_REGISTRY_ADD_SUCCESS,
  SET_REGISTRY_ADD_ERROR,
  CLEAR_REGISTRY_ADD_ERROR,
  SET_REGISTRY_UPDATE_DIALOG,
  SET_REGISTRY_UPDATE_PENDING,
  SET_REGISTRY_UPDATE_SUCCESS,
  SET_REGISTRY_UPDATE_ERROR,
  CLEAR_REGISTRY_UPDATE_ERROR,
  SET_REGISTRY_REMOVE_DIALOG,
  SET_REGISTRY_REMOVE_PENDING,
  SET_REGISTRY_REMOVE_SUCCESS,
  SET_REGISTRY_REMOVE_ERROR,
  SET_NOTIFICATION_DRIVERS_FETCH_PENDING,
  SET_NOTIFICATION_DRIVERS_FETCH_SUCCESS,
  SET_NOTIFICATION_DRIVERS_FETCH_ERROR,
  SET_NOTIFICATION_DRIVERS_UPDATE_PENDING,
  SET_NOTIFICATION_DRIVERS_UPDATE_SUCCESS,
  SET_NOTIFICATION_DRIVERS_UPDATE_ERROR,
  CLEAR_REGISTRY_REMOVE_ERROR,
  SET_PASSWORD_UPDATE_PENDING,
  SET_PASSWORD_UPDATE_SUCCESS,
  SET_PASSWORD_UPDATE_ERROR,
  SET_PASSWORD_UPDATE_RESET,
  SET_ACCOUNTS_FETCH_PENDING,
  SET_ACCOUNTS_FETCH_SUCCESS,
  SET_ACCOUNTS_FETCH_ERROR,
  SET_ACCOUNT_FETCH_PENDING,
  SET_ACCOUNT_FETCH_SUCCESS,
  SET_ACCOUNT_FETCH_ERROR,
  SET_ACCOUNT_CREATE_DIALOG,
  SET_ACCOUNT_CREATE_PENDING,
  SET_ACCOUNT_CREATE_SUCCESS,
  SET_ACCOUNT_CREATE_ERROR,
  SET_ACCOUNT_UPDATE_PENDING,
  SET_ACCOUNT_UPDATE_SUCCESS,
  SET_ACCOUNT_UPDATE_ERROR,
  SET_ACCOUNT_ACTIVE_PENDING,
  SET_ACCOUNT_ACTIVE_SUCCESS,
  SET_ACCOUNT_ACTIVE_ERROR,
  SET_ACCOUNT_REMOVE_DIALOG,
  SET_ACCOUNT_REMOVE_PENDING,
  SET_ACCOUNT_REMOVE_SUCCESS,
  SET_ACCOUNT_REMOVE_ERROR,
  SET_ACCOUNT_ADD_USER_DIALOG,
  SET_ACCOUNT_ADD_USER_PENDING,
  SET_ACCOUNT_ADD_USER_SUCCESS,
  SET_ACCOUNT_ADD_USER_ERROR,
  SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_PENDING,
  SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_SUCCESS,
  SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_ERROR,
  SET_ACCOUNT_ADD_LDAP_USER_MAPPING_PENDING,
  SET_ACCOUNT_ADD_LDAP_USER_MAPPING_SUCCESS,
  SET_ACCOUNT_ADD_LDAP_USER_MAPPING_ERROR,
  SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_PENDING,
  SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_SUCCESS,
  SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_ERROR,
  SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_PENDING,
  SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_SUCCESS,
  SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_ERROR,
  SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_PENDING,
  SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_SUCCESS,
  SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_ERROR,
  SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_PENDING,
  SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_SUCCESS,
  SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_ERROR,
  SET_ACCOUNT_UPDATE_USER_DIALOG,
  SET_ACCOUNT_UPDATE_USER_CREDENTIALS_PENDING,
  SET_ACCOUNT_UPDATE_USER_CREDENTIALS_SUCCESS,
  SET_ACCOUNT_UPDATE_USER_CREDENTIALS_ERROR,
  SET_ACCOUNT_REMOVE_USER_DIALOG,
  SET_ACCOUNT_REMOVE_USER_PENDING,
  SET_ACCOUNT_REMOVE_USER_SUCCESS,
  SET_ACCOUNT_REMOVE_USER_ERROR,
  SET_ACCOUNT_VIEW_ROLE_DIALOG,
  SET_ACCOUNT_UPDATE_CONTEXT_PENDING,
  SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS,
  SET_ACCOUNT_UPDATE_CONTEXT_ERROR,
  SET_ROLES_FETCH_PENDING,
  SET_ROLES_FETCH_SUCCESS,
  SET_ROLES_FETCH_ERROR,
  SET_ACCOUNTS_USER_ROLES_FETCH_PENDING,
  SET_ACCOUNTS_USER_ROLES_FETCH_SUCCESS,
  SET_ACCOUNTS_USER_ROLES_FETCH_ERROR,
  SET_ROLES_UPDATE_MEMBERS_PENDING,
  SET_ROLES_UPDATE_MEMBERS_SUCCESS,
  SET_ROLES_UPDATE_MEMBERS_ERROR,
  SET_SAML_IDPS_FETCH_PENDING,
  SET_SAML_IDPS_FETCH_SUCCESS,
  SET_SAML_IDPS_FETCH_ERROR,
  SET_SAML_IDP_ADD_PENDING,
  SET_SAML_IDP_ADD_SUCCESS,
  SET_SAML_IDP_ADD_ERROR,
  SET_SAML_IDP_UPDATE_PENDING,
  SET_SAML_IDP_UPDATE_SUCCESS,
  SET_SAML_IDP_UPDATE_ERROR,
  SET_SAML_IDP_REMOVE_PENDING,
  SET_SAML_IDP_REMOVE_SUCCESS,
  SET_SAML_IDP_REMOVE_ERROR,
  SET_ADD_SAML_IDP_DIALOG,
  SET_UPDATE_SAML_IDP_DIALOG,
  SET_REMOVE_SAML_IDP_DIALOG,
  CLEAR_SAML_IDP_ADD_ERROR,
  CLEAR_SAML_IDP_UPDATE_ERROR,
  CLEAR_SAML_IDP_REMOVE_ERROR,
} from '../actionTypes';

export interface SystemState {
  errorMsg: any;
  activeTabIndex: number;
  viewProps: {
    health: {
      feedLayout: 'card' | 'list';
      servTable: TableViewProps & {
        filterAvail: {
          up: boolean;
          down: boolean;
          orphan: boolean;
        };
      };
      feedTable: TableViewProps & {
        pageSize: number;
        filterSync: {
          synced: boolean;
          unsynced: boolean;
        };
      };
    };
    ldapConfig: {
      inputFocusIdx: number | false;
    };
    registries: TableViewProps;
    accounts: TableViewProps & {
      activeTabIndex: number;
      isEmailAddressPopupOpen: false;
      userTable: TableViewProps;
      roleTable: TableViewProps;
    };
    role: {
      activeTabIndex: number;
      permTable: {
        filterStr: string;
        page: number;
      };
      memberTable: {
        filterStr: string;
        page: number;
      };
    };
    sso: TableViewProps;
  };

  // Health Data Structures
  isViewServiceModalOpen: string | false;
  isViewFeedModalOpen: string | false;

  // LDAP Config Structures
  isFetchingLdapConfig: boolean;
  fetchLdapConfigError: any;
  ldapConfig: any;
  isUpdatingLdapConfig: boolean;
  updateLdapConfigError: any;
  isTestingLdapConfig: boolean;
  testLdapConfigError: any;
  testLdapConfig: any;

  // Registry Data Structures
  isFetchingRegistries: boolean;
  fetchRegistriesError: any;
  registries: RegistryConfiguration[] | false;

  isAddRegistryModalOpen: string | false;
  isAddingRegistry: boolean;
  addRegistryError: any;

  isUpdateRegistryModalOpen: string | false;
  isUpdatingRegistry: boolean;
  updateRegistryError: any;

  isRemoveRegistryModalOpen: string | false;
  isRemovingRegistry: boolean;
  removeRegistryError: any;

  // Notifications Data Structures
  isFetchingNotificationDrivers: boolean;
  fetchNotificationDriversError: any;
  isUpdatingNotificationDrivers: boolean;
  updateNotificationDriversError: any;
  notificationDrivers: any;

  // Credentials Update Structures
  isUpdatingPassword: boolean;
  passwordUpdateError: any;
  passwordUpdateData: any;

  // Accounts Data Structures
  isFetchingAccounts: boolean;
  fetchAccountsError: any;
  isFetchingAccount: boolean;
  fetchAccountError: any;
  accounts: AccountList | false;

  isAddAccountModalOpen: string | false;
  isAddingAccount: boolean;
  addAccountError: any;

  isUpdatingAccount: boolean;
  updateAccountError: any;

  isRemoveAccountModalOpen: string | false;
  isRemovingAccount: boolean;
  removeAccountError: any;

  isAddUserModalOpen: string | false;
  isAddingUser: boolean;
  addUserError: any;

  isFetchingLdapUserMappings: boolean;
  fetchLdapUserMappingsError: any;
  userMappings: any;

  isAddingLdapUserMapping: boolean;
  addLdapUserMappingError: any;

  isUpdatingLdapUserMapping: boolean;
  updateLdapUserMappingError: any;

  isRemovingLdapUserMapping: boolean;
  removeLdapUserMappingError: any;

  isReorderingLdapUserMappings: boolean;
  reorderLdapUserMappingsError: any;

  isTestingLdapUserMappings: boolean;
  testLdapUserMappingsError: any;

  isUpdateUserModalOpen: string | boolean;
  isUpdatingUser: boolean;
  updateUserError: any;

  isRemoveUserModalOpen: string | false;
  isRemovingUser: boolean;
  removeUserError: any;

  isViewRoleModalOpen: string | false;

  // Roles Data Structures
  isFetchingRoles: boolean;
  fetchRolesError: any;
  roles: RolesList | false;

  isUpdatingRolesMembers: boolean;
  updateRolesMembersError: any;

  // SAML IDPS Data Structures
  isFetchingSamlIdps: boolean;
  fetchSamlIdpsError: any;
  samlIdps: any[] | false;

  isAddingSamlIdp: boolean;
  addSamlIdpError: any;

  isUpdatingSamlIdp: boolean;
  updateSamlIdpError: any;

  isRemovingSamlIdp: boolean;
  removeSamlIdpError: any;

  isAddSamlIdpModalOpen: string | false;
  isUpdateSamlIdpModalOpen: string | false;
  isRemoveSamlIdpModalOpen: string | false;

  // Account Context Update
  isUpdatingAccountContext: boolean;
  updateAccountContextError: any;

  accountsRoles: UserAcctRolesType[] | false;
  accountsGroups?: UserGroupMembership[];
  fetchAccountsUserRolesError: any;
  isFetchingAccountsUserRoles: boolean;
}

export const defaultState: SystemState = {
  // General Props
  errorMsg: false,
  activeTabIndex: 0,
  viewProps: {
    health: {
      feedLayout: 'card',
      servTable: {
        filterStr: '',
        page: 0,
        pageSize: 10,
        filterAvail: {
          up: true,
          down: true,
          orphan: false,
        },
      },
      feedTable: {
        filterStr: '',
        page: 0,
        pageSize: 10,
        filterSync: {
          synced: true,
          unsynced: true,
        },
      },
    },
    ldapConfig: {
      inputFocusIdx: false,
    },
    registries: {
      filterStr: '',
      page: 0,
      pageSize: 10,
    },
    accounts: {
      filterStr: '',
      page: 0,
      pageSize: 10,
      activeTabIndex: 0,
      isEmailAddressPopupOpen: false,
      userTable: {
        filterStr: '',
        page: 0,
        pageSize: 10,
      },
      roleTable: {
        filterStr: '',
        page: 0,
        pageSize: 10,
      },
    },
    role: {
      activeTabIndex: 0,
      permTable: {
        filterStr: '',
        page: 0,
      },
      memberTable: {
        filterStr: '',
        page: 0,
      },
    },
    sso: {
      filterStr: '',
      page: 0,
      pageSize: 10,
    },
  },

  // Health Data Structures
  isViewServiceModalOpen: false,
  isViewFeedModalOpen: false,

  // LDAP Config Structures
  isFetchingLdapConfig: false,
  fetchLdapConfigError: false,
  ldapConfig: false,
  isUpdatingLdapConfig: false,
  updateLdapConfigError: false,
  isTestingLdapConfig: false,
  testLdapConfigError: false,
  testLdapConfig: false,

  // Registry Data Structures
  isFetchingRegistries: false,
  fetchRegistriesError: false,
  registries: false,

  isAddRegistryModalOpen: false,
  isAddingRegistry: false,
  addRegistryError: false,

  isUpdateRegistryModalOpen: false,
  isUpdatingRegistry: false,
  updateRegistryError: false,

  isRemoveRegistryModalOpen: false,
  isRemovingRegistry: false,
  removeRegistryError: false,

  // Notifications Data Structures
  isFetchingNotificationDrivers: false,
  fetchNotificationDriversError: false,
  isUpdatingNotificationDrivers: false,
  updateNotificationDriversError: false,
  notificationDrivers: false,

  // Credentials Update Structures
  isUpdatingPassword: false,
  passwordUpdateError: false,
  passwordUpdateData: false,

  // Accounts Data Structures
  isFetchingAccounts: false,
  fetchAccountsError: false,
  isFetchingAccount: false,
  fetchAccountError: false,
  accounts: false,

  isAddAccountModalOpen: false,
  isAddingAccount: false,
  addAccountError: false,

  isUpdatingAccount: false,
  updateAccountError: false,

  isRemoveAccountModalOpen: false,
  isRemovingAccount: false,
  removeAccountError: false,

  isAddUserModalOpen: false,
  isAddingUser: false,
  addUserError: false,

  isFetchingLdapUserMappings: false,
  fetchLdapUserMappingsError: false,
  userMappings: false,

  isAddingLdapUserMapping: false,
  addLdapUserMappingError: false,

  isUpdatingLdapUserMapping: false,
  updateLdapUserMappingError: false,

  isRemovingLdapUserMapping: false,
  removeLdapUserMappingError: false,

  isReorderingLdapUserMappings: false,
  reorderLdapUserMappingsError: false,

  isTestingLdapUserMappings: false,
  testLdapUserMappingsError: false,

  isUpdateUserModalOpen: false,
  isUpdatingUser: false,
  updateUserError: false,

  isRemoveUserModalOpen: false,
  isRemovingUser: false,
  removeUserError: false,

  isViewRoleModalOpen: false,

  // Roles Data Structures
  isFetchingRoles: false,
  fetchRolesError: false,
  roles: false,

  isUpdatingRolesMembers: false,
  updateRolesMembersError: false,

  // SAML IDPS Data Structures
  isFetchingSamlIdps: false,
  fetchSamlIdpsError: false,
  samlIdps: false,

  isAddingSamlIdp: false,
  addSamlIdpError: false,

  isUpdatingSamlIdp: false,
  updateSamlIdpError: false,

  isRemovingSamlIdp: false,
  removeSamlIdpError: false,

  isAddSamlIdpModalOpen: false,
  isUpdateSamlIdpModalOpen: false,
  isRemoveSamlIdpModalOpen: false,

  // Account Context Update
  isUpdatingAccountContext: false,
  updateAccountContextError: false,

  accountsRoles: false,
  fetchAccountsUserRolesError: false,
  isFetchingAccountsUserRoles: false,
};

const reducer: Reducer<SystemState> = (
  state = defaultState,
  action: any = {},
) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * SYSTEM VIEW REDUCERS
     */
    case SET_SYSTEM_ACTIVE_TAB:
      return {
        ...state,
        activeTabIndex: action.idx,
        errorMsg: false,
      };

    case SET_SYSTEM_VIEW_PROPERTIES:
      return {
        ...state,
        viewProps: action.viewProps,
      };

    /**
     * *************************************************************************
     * HEALTH VIEW REDUCERS
     */
    case SET_SERVICE_VIEW_MODAL:
      return {
        ...state,
        isViewServiceModalOpen: action.open,
      };

    case SET_FEED_VIEW_MODAL:
      return {
        ...state,
        isViewFeedModalOpen: action.open,
      };

    /**
     * *************************************************************************
     * REGISTRY VIEW REDUCERS
     */
    case SET_REGISTRY_ADD_DIALOG:
      return {
        ...state,
        isAddRegistryModalOpen: action.open,
        addRegistryError: false,
        errorMsg: false,
      };

    case SET_REGISTRY_UPDATE_DIALOG:
      return {
        ...state,
        isUpdateRegistryModalOpen: action.open,
        updateRegistryError: false,
        errorMsg: false,
      };

    case SET_REGISTRY_REMOVE_DIALOG:
      return {
        ...state,
        isRemoveRegistryModalOpen: action.open,
        removeRegistryError: false,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * LDAP CONFIG DATA REDUCERS
     */
    case SET_LDAP_CONFIG_FETCH_PENDING:
      return {
        ...state,
        isFetchingLdapConfig: true,
      };
    case SET_LDAP_CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingLdapConfig: false,
        fetchLdapConfigError: false,
        errorMsg: false,
        ldapConfig: action.resp.data,
      };
    case SET_LDAP_CONFIG_FETCH_ERROR:
      return {
        ...state,
        isFetchingLdapConfig: false,
        fetchLdapConfigError: true,
        errorMsg: action.errorMsg,
        ldapConfig: false,
      };

    case SET_LDAP_CONFIG_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingLdapConfig: true,
        updateLdapConfigError: false,
        errorMsg: false,
      };
    case SET_LDAP_CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingLdapConfig: false,
        updateLdapConfigError: false,
        errorMsg: false,
        ldapConfig: action.resp.data,
      };
    case SET_LDAP_CONFIG_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingLdapConfig: false,
        updateLdapConfigError: true,
        errorMsg: action.errorMsg,
      };

    case SET_LDAP_CONFIG_TEST_PENDING:
      return {
        ...state,
        isTestingLdapConfig: true,
        testLdapConfigError: false,
        errorMsg: false,
      };
    case SET_LDAP_CONFIG_TEST_SUCCESS:
      return {
        ...state,
        isTestingLdapConfig: false,
        testLdapConfigError: false,
        errorMsg: false,
        testLdapConfig: action.resp.data,
      };
    case SET_LDAP_CONFIG_TEST_ERROR:
      return {
        ...state,
        isTestingLdapConfig: false,
        testLdapConfigError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * REGISTRY DATA REDUCERS
     */
    case SET_REGISTRIES_FETCH_PENDING:
      return {
        ...state,
        isFetchingRegistries: true,
      };
    case SET_REGISTRIES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingRegistries: false,
        fetchRegistriesError: false,
        errorMsg: false,
        registries: action.resp.data,
      };
    case SET_REGISTRIES_FETCH_ERROR:
      return {
        ...state,
        isFetchingRegistries: false,
        fetchRegistriesError: true,
        errorMsg: action.errorMsg,
        registries: false,
      };

    case SET_REGISTRY_ADD_PENDING:
      return {
        ...state,
        isAddingRegistry: true,
      };
    case SET_REGISTRY_ADD_SUCCESS: {
      const obj = {
        ...state,
        isAddRegistryModalOpen: false,
        isAddingRegistry: false,
        addRegistryError: false,
        errorMsg: false,
      };
      obj.registries = obj.registries
        ? obj.registries.concat(action.resp.data)
        : action.resp.data;
      return obj;
    }
    case SET_REGISTRY_ADD_ERROR:
      return {
        ...state,
        isAddingRegistry: false,
        addRegistryError: true,
        errorMsg: action.errorMsg,
      };
    case CLEAR_REGISTRY_ADD_ERROR:
      return {
        ...state,
        addRegistryError: false,
        errorMsg: false,
      };

    case SET_REGISTRY_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingRegistry: true,
      };
    case SET_REGISTRY_UPDATE_SUCCESS: {
      const obj = {
        ...state,
        isUpdateRegistryModalOpen: false,
        isUpdatingRegistry: false,
        updateRegistryError: false,
        errorMsg: false,
      };
      const updatedReg = action.resp.data[0];
      obj.registries =
        obj.registries &&
        obj.registries.map(item => {
          // Replace existing registry with updated data
          if (item.registry === updatedReg.registry) {
            return updatedReg;
          }
          return item;
        });
      return obj;
    }
    case SET_REGISTRY_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingRegistry: false,
        updateRegistryError: true,
        errorMsg: action.errorMsg,
      };
    case CLEAR_REGISTRY_UPDATE_ERROR:
      return {
        ...state,
        updateRegistryError: false,
        errorMsg: false,
      };

    case SET_REGISTRY_REMOVE_PENDING:
      return {
        ...state,
        isRemovingRegistry: true,
      };
    case SET_REGISTRY_REMOVE_SUCCESS: {
      const obj = {
        ...state,
        isRemoveRegistryModalOpen: false,
        isRemovingRegistry: false,
        removeRegistryError: false,
        errorMsg: false,
      };
      obj.registries =
        obj.registries &&
        obj.registries.filter(item => item.registry !== action.resp.data);
      return obj;
    }
    case SET_REGISTRY_REMOVE_ERROR:
      return {
        ...state,
        isRemovingRegistry: false,
        removeRegistryError: true,
        errorMsg: action.errorMsg,
      };
    case CLEAR_REGISTRY_REMOVE_ERROR:
      return {
        ...state,
        removeRegistryError: false,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * NOTIFICATIONS DATA REDUCERS
     */
    case SET_NOTIFICATION_DRIVERS_FETCH_PENDING:
      return {
        ...state,
        isFetchingNotificationDrivers: true,
      };
    case SET_NOTIFICATION_DRIVERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingNotificationDrivers: false,
        fetchNotificationDriversError: false,
        errorMsg: false,
        notificationDrivers: action.resp.data,
      };
    case SET_NOTIFICATION_DRIVERS_FETCH_ERROR:
      return {
        ...state,
        isFetchingNotificationDrivers: false,
        fetchNotificationDriversError: true,
        errorMsg: action.errorMsg,
        notificationDrivers: false,
      };

    case SET_NOTIFICATION_DRIVERS_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingNotificationDrivers: action.name,
      };
    case SET_NOTIFICATION_DRIVERS_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingNotificationDrivers: false,
        updateNotificationDriversError: false,
        errorMsg: false,
        notificationDrivers: action.resp.data,
      };
    case SET_NOTIFICATION_DRIVERS_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingNotificationDrivers: false,
        updateNotificationDriversError: true,
        errorMsg: action.errorMsg,
        notificationDrivers: false,
      };

    /**
     * *************************************************************************
     * CREDENTIALS UPDATE REDUCERS
     */
    case SET_PASSWORD_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingPassword: true,
        passwordUpdateError: false,
        errorMsg: false,
        passwordUpdateData: false,
      };
    case SET_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingPassword: false,
        passwordUpdateError: false,
        errorMsg: false,
        passwordUpdateData: action.resp.data,
      };
    case SET_PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingPassword: false,
        passwordUpdateError: true,
        errorMsg: action.errorMsg,
        passwordUpdateData: false,
      };
    case SET_PASSWORD_UPDATE_RESET:
      return {
        ...state,
        isUpdatingPassword: false,
        passwordUpdateError: false,
        errorMsg: false,
        passwordUpdateData: false,
      };

    /**
     * *************************************************************************
     * ACCOUNT VIEW REDUCERS
     */
    case SET_ACCOUNT_CREATE_DIALOG:
      return {
        ...state,
        isAddAccountModalOpen: action.open,
        addAccountError: false,
        errorMsg: false,
      };

    case SET_ACCOUNT_REMOVE_DIALOG:
      return {
        ...state,
        isRemoveAccountModalOpen: action.open,
        removeAccountError: false,
        errorMsg: false,
      };

    case SET_ACCOUNT_ADD_USER_DIALOG:
      return {
        ...state,
        isAddUserModalOpen: action.open,
        addUserError: false,
        errorMsg: false,
      };

    case SET_ACCOUNT_UPDATE_USER_DIALOG:
      return {
        ...state,
        isUpdateUserModalOpen: action.open,
        updateUserError: false,
        errorMsg: false,
      };

    case SET_ACCOUNT_REMOVE_USER_DIALOG:
      return {
        ...state,
        isRemoveUserModalOpen: action.open,
        removeUserError: false,
        errorMsg: false,
      };

    case SET_ACCOUNT_VIEW_ROLE_DIALOG:
      return {
        ...state,
        isViewRoleModalOpen: action.open,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * ACCOUNT DATA REDUCERS
     */
    case SET_ACCOUNTS_FETCH_PENDING:
      return {
        ...state,
        isFetchingAccounts: true,
        fetchAccountsError: false,
        errorMsg: false,
        accounts: false,
      };
    case SET_ACCOUNTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAccounts: false,
        fetchAccountsError: false,
        errorMsg: false,
        accounts: action.resp.data,
      };
    case SET_ACCOUNTS_FETCH_ERROR:
      return {
        ...state,
        isFetchingAccounts: false,
        fetchAccountsError: true,
        errorMsg: action.errorMsg,
        accounts: false,
      };

    case SET_ACCOUNT_FETCH_PENDING:
      return {
        ...state,
        isFetchingAccount: true,
        fetchAccountError: false,
        errorMsg: false,
        accounts: false,
      };
    case SET_ACCOUNT_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAccount: false,
        fetchAccountError: false,
        errorMsg: false,
        accounts: action.resp.data,
      };
    case SET_ACCOUNT_FETCH_ERROR:
      return {
        ...state,
        isFetchingAccount: false,
        fetchAccountError: true,
        errorMsg: action.errorMsg,
        accounts: false,
      };

    case SET_ACCOUNT_CREATE_PENDING:
      return {
        ...state,
        isAddingAccount: true,
        addAccountError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        isAddAccountModalOpen: false,
        isAddingAccount: false,
        addAccountError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_CREATE_ERROR:
      return {
        ...state,
        isAddingAccount: false,
        addAccountError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingAccount: true,
        updateAccountError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingAccount: false,
        updateAccountError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingAccount: false,
        updateAccountError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_ACTIVE_PENDING:
      return {
        ...state,
        isUpdatingAccount: true,
        updateAccountError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_ACTIVE_SUCCESS:
      return {
        ...state,
        isUpdatingAccount: false,
        updateAccountError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_ACTIVE_ERROR:
      return {
        ...state,
        isUpdatingAccount: false,
        updateAccountError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_REMOVE_PENDING:
      return {
        ...state,
        isRemovingAccount: true,
        removeAccountError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_REMOVE_SUCCESS:
      return {
        ...state,
        isRemoveAccountModalOpen: false,
        isRemovingAccount: false,
        removeAccountError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_REMOVE_ERROR:
      return {
        ...state,
        isRemovingAccount: false,
        removeAccountError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_ADD_USER_PENDING:
      return {
        ...state,
        isAddingUser: true,
        addUserError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_ADD_USER_SUCCESS:
      return {
        ...state,
        isAddingUser: false,
        addUserError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_ADD_USER_ERROR:
      return {
        ...state,
        isAddingUser: false,
        addUserError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_PENDING:
      return {
        ...state,
        isFetchingLdapUserMappings: true,
        fetchLdapUserMappingsError: false,
        errorMsg: false,
        userMappings: false,
      };
    case SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_SUCCESS:
      return {
        ...state,
        isFetchingLdapUserMappings: false,
        fetchLdapUserMappingsError: false,
        errorMsg: false,
        userMappings: action.resp.data,
      };
    case SET_ACCOUNT_FETCH_LDAP_USER_MAPPINGS_ERROR:
      return {
        ...state,
        isFetchingLdapUserMappings: false,
        fetchLdapUserMappingsError: true,
        errorMsg: action.errorMsg,
        userMappings: false,
      };

    case SET_ACCOUNT_ADD_LDAP_USER_MAPPING_PENDING:
      return {
        ...state,
        isAddingLdapUserMapping: true,
        addLdapUserMappingError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_ADD_LDAP_USER_MAPPING_SUCCESS:
      return {
        ...state,
        isAddingLdapUserMapping: false,
        addLdapUserMappingError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_ADD_LDAP_USER_MAPPING_ERROR:
      return {
        ...state,
        isAddingLdapUserMapping: false,
        addLdapUserMappingError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_PENDING:
      return {
        ...state,
        isUpdatingLdapUserMapping: true,
        updateLdapUserMappingError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_SUCCESS:
      return {
        ...state,
        isUpdatingLdapUserMapping: false,
        updateLdapUserMappingError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_UPDATE_LDAP_USER_MAPPING_ERROR:
      return {
        ...state,
        isUpdatingLdapUserMapping: false,
        updateLdapUserMappingError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_PENDING:
      return {
        ...state,
        isRemovingLdapUserMapping: true,
        removeLdapUserMappingError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_SUCCESS:
      return {
        ...state,
        isRemoveUserModalOpen: false,
        isRemovingLdapUserMapping: false,
        removeLdapUserMappingError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_REMOVE_LDAP_USER_MAPPING_ERROR:
      return {
        ...state,
        isRemovingLdapUserMapping: false,
        removeLdapUserMappingError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_PENDING:
      return {
        ...state,
        isReorderingLdapUserMappings: true,
        reorderLdapUserMappingsError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_SUCCESS:
      return {
        ...state,
        isReorderingLdapUserMappings: false,
        reorderLdapUserMappingsError: false,
        errorMsg: false,
        userMappings: action.resp.data,
      };
    case SET_ACCOUNT_REORDER_LDAP_USER_MAPPINGS_ERROR:
      return {
        ...state,
        isReorderingLdapUserMappings: false,
        reorderLdapUserMappingsError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_PENDING:
      return {
        ...state,
        isTestingLdapUserMappings: true,
        testLdapUserMappingsError: false,
        errorMsg: false,
        testLdapMappings: false,
      };
    case SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_SUCCESS:
      return {
        ...state,
        isTestingLdapUserMappings: false,
        testLdapUserMappingsError: false,
        errorMsg: false,
        testLdapMappings: action.resp.data,
      };
    case SET_ACCOUNT_TEST_LDAP_USER_MAPPINGS_ERROR:
      return {
        ...state,
        isTestingLdapUserMappings: false,
        testLdapUserMappingsError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_UPDATE_USER_CREDENTIALS_PENDING:
      return {
        ...state,
        isUpdatingUser: true,
        updateUserError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_UPDATE_USER_CREDENTIALS_SUCCESS: {
      const {
        account,
        username,
        created_at: passwordLastUpdated,
      } = action.resp.data;

      // Set the timestamp of the user's password last updated field
      const acct =
        state.accounts && state.accounts?.find(item => item.name === account);
      const user = acct && acct.users?.find(item => item.username === username);
      if (user) user.password_last_updated = passwordLastUpdated;

      return {
        ...state,
        isUpdatingUser: false,
        updateUserError: false,
        errorMsg: false,
      };
    }
    case SET_ACCOUNT_UPDATE_USER_CREDENTIALS_ERROR:
      return {
        ...state,
        isUpdatingUser: false,
        updateUserError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_REMOVE_USER_PENDING:
      return {
        ...state,
        isRemovingUser: true,
        removeUserError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_REMOVE_USER_SUCCESS:
      return {
        ...state,
        isRemoveUserModalOpen: false,
        isRemovingUser: false,
        removeUserError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_REMOVE_USER_ERROR:
      return {
        ...state,
        isRemovingUser: false,
        removeUserError: true,
        errorMsg: action.errorMsg,
      };

    case SET_ACCOUNT_UPDATE_CONTEXT_PENDING:
      return {
        ...state,
        isUpdatingAccountContext: true,
        updateAccountContextError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_UPDATE_CONTEXT_SUCCESS:
      return {
        ...state,
        isUpdatingAccountContext: false,
        updateAccountContextError: false,
        errorMsg: false,
      };
    case SET_ACCOUNT_UPDATE_CONTEXT_ERROR:
      return {
        ...state,
        isUpdatingAccountContext: false,
        updateAccountContextError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * ROLE DATA REDUCERS
     */
    case SET_ACCOUNTS_USER_ROLES_FETCH_PENDING:
      return {
        ...state,
        isFetchingAccountsUserRoles: true,
        fetchAccountsUserRolesError: false,
        errorMsg: false,
        accountsRoles: false,
      };
    case SET_ACCOUNTS_USER_ROLES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAccountsUserRoles: false,
        fetchAccountsUserRolesError: false,
        errorMsg: false,
        accountsRoles: action.resp.data.accountsRoles,
        accountsGroups: action.resp.data.accountsGroups,
      };
    case SET_ACCOUNTS_USER_ROLES_FETCH_ERROR:
      return {
        ...state,
        isFetchingAccountsUserRoles: false,
        fetchAccountsUserRolesError: true,
        errorMsg: action.errorMsg,
        accountsRoles: false,
      };

    case SET_ROLES_FETCH_PENDING:
      return {
        ...state,
        isFetchingRoles: true,
        fetchRolesError: false,
        errorMsg: false,
        roles: false,
      };
    case SET_ROLES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingRoles: false,
        fetchRolesError: false,
        errorMsg: false,
        roles: action.resp.data,
      };
    case SET_ROLES_FETCH_ERROR:
      return {
        ...state,
        isFetchingRoles: false,
        fetchRolesError: true,
        errorMsg: action.errorMsg,
        roles: false,
      };

    case SET_ROLES_UPDATE_MEMBERS_PENDING:
      return {
        ...state,
        isUpdatingRolesMembers: true,
        updateRolesMembersError: false,
        errorMsg: false,
      };
    case SET_ROLES_UPDATE_MEMBERS_SUCCESS: {
      const { roles } = state;
      const updatedRoles = action.resp.data;
      // Patch members listings in updated roles (but only when the response is
      // for a role being updated within the scope of an account - that is, not
      // from within the LDAP config tab which isn't scoped to any account)
      if (roles && roles.list) {
        roles.list = roles.list.map(role => {
          const r = role;
          const roleWasUpdated = updatedRoles.find(
            item => item.name === r.name,
          );
          if (roleWasUpdated) {
            const { members } = roleWasUpdated;
            r.members = members;
          }
          return r;
        });
      }

      return {
        ...state,
        isUpdatingRolesMembers: false,
        updateRolesMembersError: false,
        errorMsg: false,
        roles,
      };
    }
    case SET_ROLES_UPDATE_MEMBERS_ERROR:
      return {
        ...state,
        isUpdatingRolesMembers: false,
        updateRolesMembersError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * SSO VIEW REDUCERS
     */
    case SET_ADD_SAML_IDP_DIALOG:
      return {
        ...state,
        isAddSamlIdpModalOpen: action.open,
        addSamlIdpError: false,
        errorMsg: false,
      };

    case SET_UPDATE_SAML_IDP_DIALOG:
      return {
        ...state,
        isUpdateSamlIdpModalOpen: action.open,
        updateSamlIdpError: false,
        errorMsg: false,
      };

    case SET_REMOVE_SAML_IDP_DIALOG:
      return {
        ...state,
        isRemoveSamlIdpModalOpen: action.open,
        removeSamlIdpError: false,
        errorMsg: false,
      };

    /**
     * *************************************************************************
     * SAML IDPS DATA REDUCERS
     */
    case SET_SAML_IDPS_FETCH_PENDING:
      return {
        ...state,
        isFetchingSamlIdps: true,
        fetchSamlIdpsError: false,
        errorMsg: false,
        samlIdps: false,
      };
    case SET_SAML_IDPS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingSamlIdps: false,
        fetchSamlIdpsError: false,
        errorMsg: false,
        samlIdps: action.resp.data.list,
        samlMap: action.resp.data.map,
      };
    case SET_SAML_IDPS_FETCH_ERROR:
      return {
        ...state,
        isFetchingSamlIdps: false,
        fetchSamlIdpsError: true,
        errorMsg: action.errorMsg,
        samlIdps: false,
      };

    case SET_SAML_IDP_ADD_PENDING:
      return {
        ...state,
        isAddingSamlIdp: true,
        addSamlIdpError: false,
        errorMsg: false,
      };
    case SET_SAML_IDP_ADD_SUCCESS:
      return {
        ...state,
        isAddingSamlIdp: false,
        addSamlIdpError: false,
        errorMsg: false,
        samlIdps: false,
        isAddSamlIdpModalOpen: false,
      };
    case SET_SAML_IDP_ADD_ERROR:
      return {
        ...state,
        isAddingSamlIdp: false,
        addSamlIdpError: true,
        errorMsg: action.errorMsg,
      };
    case CLEAR_SAML_IDP_ADD_ERROR:
      return {
        ...state,
        addSamlIdpError: false,
        errorMsg: false,
      };

    case SET_SAML_IDP_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingSamlIdp: true,
        updateSamlIdpError: false,
        errorMsg: false,
      };
    case SET_SAML_IDP_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingSamlIdp: false,
        updateSamlIdpError: false,
        errorMsg: false,
        samlIdps: false,
        isUpdateSamlIdpModalOpen: false,
      };
    case SET_SAML_IDP_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingSamlIdp: false,
        updateSamlIdpError: true,
        errorMsg: action.errorMsg,
      };
    case CLEAR_SAML_IDP_UPDATE_ERROR:
      return {
        ...state,
        updateSamlIdpError: false,
        errorMsg: false,
      };

    case SET_SAML_IDP_REMOVE_PENDING:
      return {
        ...state,
        isRemovingSamlIdp: true,
      };
    case SET_SAML_IDP_REMOVE_SUCCESS: {
      return {
        ...state,
        isRemoveSamlIdpModalOpen: false,
        isRemovingSamlIdp: false,
        removeSamlIdpError: false,
        samlIdps: false,
        errorMsg: false,
      };
    }
    case SET_SAML_IDP_REMOVE_ERROR:
      return {
        ...state,
        isRemovingSamlIdp: false,
        removeSamlIdpError: true,
        errorMsg: action.errorMsg,
      };
    case CLEAR_SAML_IDP_REMOVE_ERROR:
      return {
        ...state,
        removeSamlIdpError: false,
        errorMsg: false,
      };

    default:
      return state;
  }
};

export default reducer;
