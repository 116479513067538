import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { Button } from 'semantic-ui-react';

import type {
  DataEntity,
  DeepPartial,
  ReportColumnList,
  ReportExecution,
  ReportFilterList,
  ReportTemplate,
  Report,
  ReportResults,
  ServiceError,
  TableViewProps,
  GetReportResultsParams,
  GetReportResultsResponse,
  GetReportPreviewParams,
  GetReportPreviewResponse,
  PostReportTemplateParams,
  PostReportTemplateResponse,
  PostReportExecutionParams,
  PostReportExecutionResponse,
  PutReportTemplateParams,
  PutReportTemplateResponse,
  DeleteReportTemplateParams,
  DeleteReportTemplateResponse,
  DeleteReportParams,
  DeleteReportResponse,
  DeleteReportExecutionParams,
  DeleteReportExecutionResponse,
  GetReportExecutionsParams,
  GetReportExecutionsResponse,
  GetReportTemplatesResponse,
  GetReportsResponse,
  PostReportParams,
  PostReportResponse,
  PatchReportResponse,
  PatchReportParams,
  DataUpdateEvent,
  UpdateReportExecutionParams,
  UpdateReportExecutionResponse,
} from '@models';
import { nullDataEntity, initialTableViewProps } from '@models';
import Link from '@shared/components/Link';

import {
  createEntityReducers,
  createEntityAction,
  getEntityActions,
  getEntityObject,
} from './common';
import { showModal, hideModal } from './modals.slice';
import type { RootState } from './store';

const customStatusMessages: Record<string, string> = {
  error: 'unable to complete. Please try again',
  complete: 'complete and ready for download',
};

// --- Entities ----------------------------------------------------------------
const checkAppDBHealth = (state: RootState) =>
  state.app.healthCheck.isAppDBHealthy;

const checkReportsHealth = (state: RootState) =>
  state.app.healthCheck.isReportsEnabledAndActive?.active &&
  state.app.healthCheck.isReportsEnabledAndActive?.enabled;

const entities = {
  fetchTemplates: createEntityAction<void, GetReportTemplatesResponse>({
    stateKey: 'templates',
    servicePath: () => '/service/reports/templates',
    condition: checkAppDBHealth,
  }),
  fetchReports: createEntityAction<void, GetReportsResponse>({
    stateKey: 'savedReports',
    servicePath: () => '/service/reports',
    condition: (state: RootState) =>
      checkAppDBHealth(state) && checkReportsHealth(state),
  }),
  fetchReportResults: createEntityAction<
    GetReportResultsParams,
    GetReportResultsResponse
  >({
    stateKey: 'reportResults',
    servicePath: ({ reportId, executionId }) =>
      `/service/reports/${reportId}/result/${executionId}`,
    condition: checkReportsHealth,
  }),
  fetchReportPreview: createEntityAction<
    GetReportPreviewParams,
    GetReportPreviewResponse
  >({
    stateKey: 'inProgress/preview',
    servicePath: () => '/service/reports/preview',
    condition: checkReportsHealth,
    // GraphQL 'get' requires an underlying POST request to send the query body
    customRequest: (req, path, params) => req.post(path).send(params),
  }),
};

export const {
  fetchTemplates,
  fetchReports,
  fetchReportResults,
  fetchReportPreview,
} = getEntityActions(entities);

export const createReport = createEntityAction<
  PostReportParams,
  PostReportResponse
>({
  verb: 'post',
  stateKey: 'savedReports',
  servicePath: () => `/service/reports`,
  condition: (state: RootState) =>
    checkAppDBHealth(state) && checkReportsHealth(state),
}).thunk;

export const updateReport = createEntityAction<
  PatchReportParams,
  PatchReportResponse
>({
  verb: 'patch',
  stateKey: 'savedReports',
  servicePath: () => `/service/reports`,
  condition: (state: RootState) =>
    checkAppDBHealth(state) && checkReportsHealth(state),
  toastOnFailure: ({ name }, error) => (
    <span>
      <p>
        An error occurred while attempting to update report{' '}
        <strong>{name}</strong>.
      </p>
      {error?.message}
    </span>
  ),
  toastOnSuccess: ({ name }) => (
    <span>
      Report <strong>{name}</strong> was successfully updated.
    </span>
  ),
  getToastId: ({ uuid }) => uuid,
}).thunk;

export const deleteReport = createEntityAction<
  DeleteReportParams,
  DeleteReportResponse
>({
  verb: 'delete',
  stateKey: 'savedReports',
  servicePath: ({ id }) => `/service/reports/${id}`,
  condition: (state: RootState) =>
    checkAppDBHealth(state) && checkReportsHealth(state),
  // Ensure the isGlobal value is passed to the service if true
  customRequest: (req, path, { isGlobal }) =>
    req.delete(path).query({ isGlobal: isGlobal || undefined }),
}).thunk;

export const createTemplate = createEntityAction<
  PostReportTemplateParams,
  PostReportTemplateResponse
>({
  verb: 'post',
  stateKey: 'templates',
  servicePath: () => `/service/reports/templates`,
  condition: checkAppDBHealth,
}).thunk;

export const updateTemplate = createEntityAction<
  PutReportTemplateParams,
  PutReportTemplateResponse
>({
  verb: 'put',
  stateKey: 'templates',
  servicePath: ({ id }) => `/service/reports/templates/${id}`,
  condition: checkAppDBHealth,
}).thunk;

export const deleteTemplate = createEntityAction<
  DeleteReportTemplateParams,
  DeleteReportTemplateResponse
>({
  verb: 'delete',
  stateKey: 'templates',
  servicePath: ({ id }) => `/service/reports/templates/${id}`,
  condition: checkAppDBHealth,
}).thunk;

export const fetchReportExecutions = createEntityAction<
  GetReportExecutionsParams,
  GetReportExecutionsResponse
>({
  stateKey: 'report/executions',
  servicePath: ({ id }) => `/service/reports/${id}/executions`,
  condition: checkReportsHealth,
  customRequest: (req, path, { isGlobal, nextToken }) =>
    req.get(path).query({ isGlobal: isGlobal || undefined, nextToken }),
}).thunk;

export const executeReport = createEntityAction<
  PostReportExecutionParams,
  PostReportExecutionResponse
>({
  stateKey: 'report/executions',
  servicePath: ({ reportId }) => `/service/reports/${reportId}/executions`,
  condition: checkReportsHealth,
  verb: 'post',
  toastOnFailure: ({ name }, error, thunkApi) => {
    const { account } = thunkApi.getState().auth;
    const isAdmin = account && account.isAdmin;
    return (
      <>
        Unable to generate results for report <strong>{name}</strong>.{' '}
        {error.message.includes('Resource limit exceeded')
          ? `Resource limit for Report Executions has been met or exceeded. ${isAdmin ? 'Please check your system limits to resolve this issue.' : 'Please contact your administrator to resolve this issue.'}`
          : 'Please try again.'}
      </>
    );
  },
  toastOnSuccess: ({ name, reportId }, { body }) => (
    <>
      A new result set for the report <strong>{name}</strong> has started
      generating and its status is currently&nbsp;
      <strong>
        {body.data.status
          ? customStatusMessages[body.data.status] || body.data.status
          : 'unknown'}
      </strong>
      .
      {body.data.status === 'completed' ? (
        <div className="successToastLink">
          <Button
            as={Link}
            to={`/reports/saved/${reportId}/result/${body.data.uuid}`}
            size="mini"
            color="green"
            content="View Results"
          />
        </div>
      ) : body.data.status === 'pending' || body.data.status === 'running' ? (
        ' Please check back later to view or download the results.'
      ) : null}
    </>
  ),
  getToastId: ({ reportId }) => reportId,
}).thunk;

export const updateReportExecution = createEntityAction<
  UpdateReportExecutionParams,
  UpdateReportExecutionResponse
>({
  verb: 'put',
  stateKey: 'report/executions',
  servicePath: ({ reportId, executionId }) =>
    `/service/reports/${reportId}/executions/${executionId}`,
  condition: checkReportsHealth,
}).thunk;

export const deleteReportExecution = createEntityAction<
  DeleteReportExecutionParams,
  DeleteReportExecutionResponse
>({
  verb: 'delete',
  stateKey: 'report/executions',
  servicePath: ({ reportId, executionId }) =>
    `/service/reports/${reportId}/executions/${executionId}`,
  condition: checkReportsHealth,
  // Ensure the isGlobal value is passed to the service if true
  customRequest: (req, path, { isGlobal }) =>
    req.delete(path).query({ isGlobal: isGlobal || undefined }),
}).thunk;

// --- Types -------------------------------------------------------------------
export interface ReportPreviewEntity {
  isLoading: boolean;
  isUpdating: boolean;
  data: Record<string, any>[] | null;
  error?: ServiceError;
}

interface ReportInProgress {
  templateId: string;
  filters: ReportFilterList;
  columns: ReportColumnList;
  isGlobal: boolean;
  preview: ReportPreviewEntity;
  download: {
    isLoading: boolean;
    error?: ServiceError;
  };
  selectedFilter?: string;
}

export interface ReportsState {
  inProgress: ReportInProgress;
  savedReports: DataEntity<Report>;
  executions: DataEntity<ReportExecution>;
  templates: DataEntity<ReportTemplate> & {
    customIds?: string[];
    systemIds?: string[];
  };
  reportResults: DataEntity<ReportResults> & {
    metadata?: ReportExecution;
  };
  viewProps: {
    activeTabIndex: number;
    savedReports: TableViewProps & {
      expanded: Record<string, true>;
    };
    templates: {
      selectedView: 'custom' | 'system';
      custom: TableViewProps;
      system: TableViewProps;
    };
  };
}

type ReportsViewProps = ReportsState['viewProps'];

// Payload of report in progress update action:
//   - when setting the template id, the columns and filters props are required
//     while all other properties are optional
//   - if templateId is not set, then any other prop(s) can be provided
type NewReportUpdatePropsWithTemplate = Pick<
  ReportInProgress,
  'templateId' | 'columns' | 'filters'
>;
type NewReportOtherProps = Omit<
  ReportInProgress,
  keyof NewReportUpdatePropsWithTemplate
>;
type NewReportActionPayload =
  | (NewReportUpdatePropsWithTemplate & Partial<NewReportOtherProps>)
  | (Partial<ReportInProgress> & {
      templateId?: never;
    });

// --- Default values ----------------------------------------------------------
export const emptyPreviewEntity: ReportPreviewEntity = {
  isLoading: false,
  isUpdating: false,
  data: null,
};

const initialInProgress: ReportInProgress = {
  templateId: '',
  filters: {
    data: {},
    displayOrder: [],
  },
  columns: {
    data: {},
    displayOrder: [],
  },
  isGlobal: false,
  preview: emptyPreviewEntity,
  download: {
    isLoading: false,
  },
};

export const initialState: ReportsState = {
  inProgress: initialInProgress,
  savedReports: nullDataEntity,
  executions: nullDataEntity,
  templates: nullDataEntity,
  reportResults: nullDataEntity,
  viewProps: {
    activeTabIndex: 0,
    savedReports: {
      expanded: {},
      ...initialTableViewProps,
    },
    templates: {
      selectedView: 'system',
      custom: initialTableViewProps,
      system: initialTableViewProps,
    },
  },
};

// --- Slice -------------------------------------------------------------------
const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearSavedReportsError: state => {
      delete state.savedReports.error;
    },
    setReportsViewProps: (
      state,
      action: PayloadAction<DeepPartial<ReportsViewProps>>,
    ) => {
      state.viewProps = merge(
        state.viewProps,
        action.payload,
      ) as ReportsViewProps;
    },
    updateNewReport: (state, action: PayloadAction<NewReportActionPayload>) => {
      if (action.payload.templateId) {
        state.inProgress = { ...initialInProgress, ...action.payload };
      } else {
        state.inProgress = { ...state.inProgress, ...action.payload };
      }
    },
    resetNewReport: state => {
      state.inProgress = initialInProgress;
    },
    handleReportDataEvent: (
      state,
      action: PayloadAction<{ stateKey: string; event: DataUpdateEvent }>,
    ) => {
      const { stateKey, event } = action.payload;
      const { entityId, updateType } = event;
      const [base, key] = getEntityObject(state, stateKey);
      if (!base[key].stale) {
        base[key].stale = {};
      }
      if (!(entityId in base[key].stale)) {
        base[key].stale[event.entityId] = updateType;
      }
    },
  },
  extraReducers: builder => {
    createEntityReducers(entities, builder);

    builder
      // Create Saved Report
      .addCase(createReport.pending, state => {
        state.savedReports.isUpdating = true;
      })
      .addCase(createReport.fulfilled, (state, action) => {
        const { uuid } = action.payload.body.data;
        state.savedReports.isUpdating = false;
        state.savedReports.data![uuid] = action.payload.body.data;
      })
      .addCase(createReport.rejected, (state, action) => {
        state.savedReports.isUpdating = false;
        state.savedReports.error = action.payload || action.error;
      })
      // Update Saved Report
      .addCase(updateReport.pending, state => {
        state.savedReports.isUpdating = true;
      })
      .addCase(updateReport.fulfilled, (state, action) => {
        const { uuid } = action.meta.arg;
        state.savedReports.isUpdating = false;
        state.savedReports.data![uuid] = {
          ...state.savedReports.data![uuid],
          ...action.payload.body.data,
        };
      })
      .addCase(updateReport.rejected, (state, action) => {
        state.savedReports.isUpdating = false;
        state.savedReports.error = action.payload || action.error;
      })
      // Delete Report
      .addCase(deleteReport.pending, state => {
        state.savedReports.isUpdating = true;
      })
      .addCase(deleteReport.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        state.savedReports.isUpdating = false;
        delete state.savedReports.data?.[id];
      })
      .addCase(deleteReport.rejected, (state, action) => {
        state.savedReports.isUpdating = false;
        state.savedReports.error = action.payload || action.error;
      })
      // Create Template
      .addCase(createTemplate.pending, state => {
        state.templates.isUpdating = true;
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        const { id } = action.payload.body.data;
        state.templates.isUpdating = false;
        state.templates.data![id] = action.payload.body.data;
        state.templates.customIds!.push(id);
      })
      .addCase(createTemplate.rejected, (state, action) => {
        state.templates.isUpdating = false;
        state.templates.error = action.payload || action.error;
      })
      // Update Template
      .addCase(updateTemplate.pending, state => {
        state.templates.isUpdating = true;
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        const { id } = action.payload.body.data;
        state.templates.isUpdating = false;
        state.templates.data![id] = action.payload.body.data;
      })
      .addCase(updateTemplate.rejected, (state, action) => {
        state.templates.isUpdating = false;
        state.templates.error = action.payload || action.error;
      })
      // Delete Template
      .addCase(deleteTemplate.pending, state => {
        state.templates.isUpdating = true;
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        if (state.templates.data && state.templates.customIds && id) {
          const index = state.templates.customIds.indexOf(id);
          if (index !== -1 && state.templates.data[id]) {
            delete state.templates.data?.[id];
            state.templates.customIds?.splice(index, 1);
          }
        }
        state.templates.isUpdating = false;
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.templates.isUpdating = false;
        state.templates.error = action.payload || action.error;
      })
      // Fetch Report Executions
      .addCase(fetchReportExecutions.pending, (state, action) => {
        const { nextToken } = action.meta.arg;
        if (nextToken) {
          state.executions.isUpdating = true;
        } else {
          state.executions.isLoading = true;
        }
      })
      .addCase(fetchReportExecutions.fulfilled, (state, action) => {
        const { id: reportId, nextToken } = action.meta.arg;
        const report = state.savedReports.data?.[reportId];
        state.executions.isLoading = false;
        state.executions.isUpdating = false;
        if (report) {
          const { data, executionIds } = action.payload.body;
          const isInitialFetch = nextToken === undefined;
          if (isInitialFetch) {
            // Clear previous executions
            state.executions.data = {};
            // Update lastExecution if needed
            report.lastExecution = executionIds.length
              ? data[executionIds[0]]
              : null;
          }
          // Add / Set the new executions
          state.executions.data = { ...state.executions.data, ...data };
          // Ensure error is removed as the fetch was a success
          delete state.executions.error;
        }
      })
      .addCase(fetchReportExecutions.rejected, (state, action) => {
        const { nextToken } = action.meta.arg;
        state.executions.isLoading = false;
        state.executions.isUpdating = false;
        state.executions.error = action.payload || action.error;
        // Reset data if this is a new fetch so that the error can display. If
        // this is a fetch for more executions, we prioritize showing existing
        // executions and visualize the error via toast
        if (!nextToken) state.executions.data = null;
      })
      // Execute Report
      .addCase(executeReport.pending, state => {
        state.executions.isUpdating = true;
      })
      .addCase(executeReport.fulfilled, (state, action) => {
        const execution = action.payload.body.data;
        state.executions.isUpdating = false;
        delete state.executions.error;
        state.executions.data = {
          [execution.uuid]: execution,
          ...state.executions.data,
        };
        // Update lastExecution on the related report
        const { reportId } = action.meta.arg;
        const report = state.savedReports.data?.[reportId];
        if (report) report.lastExecution = execution;
      })
      .addCase(executeReport.rejected, (state, action) => {
        state.executions.isUpdating = false;
        state.executions.error = action.payload || action.error;
      })
      // Update Report Execution
      .addCase(updateReportExecution.pending, state => {
        state.executions.isUpdating = true;
      })
      .addCase(updateReportExecution.fulfilled, (state, action) => {
        const { executionId } = action.meta.arg;
        const updatedExecution = {
          ...state.executions.data![executionId],
          ...action.payload.body.data,
        };
        state.executions.isUpdating = false;
        delete state.executions.error;
        state.executions.data = {
          ...state.executions.data,
          [executionId]: updatedExecution,
        };
        // Update lastExecution on the related report
        const { reportId } = action.meta.arg;
        const report = state.savedReports.data?.[reportId];
        if (report) report.lastExecution = updatedExecution;
      })
      .addCase(updateReportExecution.rejected, (state, action) => {
        state.executions.isUpdating = false;
        state.executions.error = action.payload || action.error;
      })
      // Delete Report Execution
      .addCase(deleteReportExecution.pending, state => {
        state.executions.isUpdating = true;
      })
      .addCase(deleteReportExecution.fulfilled, (state, action) => {
        const { executionId } = action.meta.arg;
        state.executions.isUpdating = false;
        delete state.executions.error;
        delete state.executions.data?.[executionId];
      })
      .addCase(deleteReportExecution.rejected, (state, action) => {
        state.executions.isUpdating = false;
        state.executions.error = action.payload || action.error;
      })
      // Close 'Cancel Report' Modal
      .addCase(hideModal, (state, action) => {
        if (action.payload === 'cancelReportExecution') {
          // Clear error state shared with Delete Report modal
          delete state.executions.error;
        }
      })
      // Open 'Configure Report' Modal
      .addCase(showModal, (state, action) => {
        if (action.payload.type === 'configureReport') {
          // Clear error
          delete state.savedReports.error;
        }
      });
  },
});

export const {
  setReportsViewProps,
  updateNewReport,
  resetNewReport,
  clearSavedReportsError,
  handleReportDataEvent,
} = reportsSlice.actions;

export default reportsSlice.reducer;
