import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import _startCase from 'lodash/startCase';
import type { ReactElement } from 'react';
import type { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { Button, Header, Icon } from 'semantic-ui-react';

import type { ModalType, ServiceError, ModalData } from '@models';
import CancelButton from '@shared/components/CancelButton';
import ErrMessage from '@shared/components/ErrMessage';
import type { ModalProps } from '@shared/components/Modal';
import Modal, { useModal } from '@shared/components/Modal';
import raiseToast from '@shared/components/Toast';

import s from './ConfirmationModal.scss';

/**
 * Returns a best-guess completed action name. Handled two common cases where
 * the action name does or does not end in the letter 'e'. If this leads to a
 * a bad results, use the `successMessage` prop to fully customize the success
 * message.
 *
 * @example
 * getSuccessfulActionName('duplicate') // => 'duplicated'
 * getSuccessfulActionName('add') // => 'added'
 */
export const getSuccessfulActionName = (actionName: string) => {
  if (actionName.endsWith('e')) {
    return `${actionName}d`;
  }

  return `${actionName}ed`;
};

export type ConfirmationModalRenderProp<Data, ReturnType = string> =
  | ReturnType
  | ((props: Data) => ReturnType);

export type ConfirmationModalOnConfirm<Data> = (
  event: Data,
) =>
  | boolean
  | Promise<
      | { type: string; meta?: { requestStatus: 'fulfilled' | 'rejected' } }
      | boolean
    >;

export interface ConfirmationModalProps<
  Type extends ModalType,
  Data extends ModalData<Type> = ModalData<Type>,
> extends ModalProps<Type> {
  /**
   * Appropriate icon to display in the confirmation button for the associated
   * action. Also populates the success message if no `successIcon` is provided.
   *
   * @default 'check'
   */
  actionIcon?: SemanticICONS;
  /**
   * A verb to describe the action being confirmed. Will be capitalized and used
   * in various default text contents for the component. The component internals
   * expect the action to be described in the imperative mood, i.e. "delete".
   */
  actionName: Lowercase<string>;
  /**
   * Optional text to override the cancel button label.
   */
  cancelText?: string;
  /**
   * Optional color to apply to the confirmation button.
   *
   * @default 'green'
   */
  color?: SemanticCOLORS;
  /**
   * Label text for the confirmation button. Defaults to the capitalized action
   * name.
   */
  confirmText?: string;
  /**
   * Optional render prop to override the main content of the modal. This will
   * replace the full content section, so `textHeader` and `text` props will be
   * ignored.
   *
   * @example
   * // If you need to provide custom content, you can pass in an element or a
   * // function if you need to key off of the passed data
   * <ConfirmationModal
   *   id="deleteTemplate"
   *   content={
   *     <>
   *       <Header inverted as="h3">
   *         Are you sure you want to delete this template?
   *       </Header>
   *       <p>Once you delete this template, you won&#39;t be able to recover it.</p>
   *     </>
   *   }
   *   onConfirm={({ id }) => dispatch(deleteTemplate(id))}
   *   isExecuting={isUpdating}
   *   error={error}
   * />
   */
  content?: ConfirmationModalRenderProp<Data, ReactElement>;
  /**
   * An error object to display in the modal. If provided, it will be rendered
   * into an `ErrMessage` component above the modal content.
   *
   * @see ErrMessage
   */
  error?: ServiceError;
  /**
   * Optional arbitrary content to display below the main message. This can be
   * useful when you need to provide additional content alongside the default
   * content, rather than overriding it.
   */
  extra?: ConfirmationModalRenderProp<Data, ReactElement>;
  /**
   * Indicates that the action has been confirmed and is currently executing.
   * Usually provided by a loading state attached to the action handler. When
   * true, the confirm button will be disabled and show a loading spinner.
   */
  isExecuting?: boolean;
  /**
   * An entity or process name to describe what the action will be performed on.
   *
   * @default 'item'
   */
  item?: string;
  /**
   * Confirmation callback for the action. The function can be async, and is
   * expected to return a boolean or an object carrying the resolved status.
   */
  onConfirm: ConfirmationModalOnConfirm<Data>;
  /**
   * Icon to display in the toast message when the action is successful. If not
   * provided, the `actionIcon` will be used instead.
   */
  successIcon?: SemanticICONS;
  /**
   * Message to display in the toast when the action is successful. If not
   * provided, a default message will be generated based on the action name and
   * item type.
   */
  successMessage?: ConfirmationModalRenderProp<Data>;
  /**
   * Text to display in the modal body. Defaults to a generic
   * message about the action being confirmed.
   *
   * @example
   * // For customizing the default text, you can use the 'title', 'textHeader',
   * // and 'text' props. They can be either a string or a function if you need to
   * // to key off of the passed data.
   * <ConfirmationModal
   *   id="deleteTemplate"
   *   title={({ name }) => `Delete Template "${name}""`}
   *   textHeader="Are you sure you want to delete this template?"
   *   text="Once you delete this template, you won't be able to recover it."
   *   onConfirm={({ id }) => dispatch(deleteTemplate(id))}
   *   isExecuting={isUpdating}
   *   error={error}
   * />
   */
  text?: ConfirmationModalRenderProp<Data>;
  /**
   * Optional heading text to display in the modal body. Defaults to a
   * generic message about the action being confirmed.
   */
  textHeader?: ConfirmationModalRenderProp<Data>;
  /**
   * Optional title text to display in the modal header. Defaults to a generic
   * message about the action being confirmed.
   */
  title?: ConfirmationModalRenderProp<Data>;
}

/**
 * A generic confirmation modal, typically useful for destructive actions that
 * require user confirmation.
 */
const ConfirmationModal = <
  Type extends ModalType,
  Data extends ModalData<Type> = ModalData<Type>,
>({
  actionIcon = 'check',
  actionName,
  cancelText,
  className,
  color = 'green',
  confirmText,
  content,
  error,
  extra,
  id,
  isExecuting,
  item = 'item',
  onConfirm,
  successIcon,
  successMessage,
  text,
  textHeader,
  title,
  ...props
}: ConfirmationModalProps<Type, Data>) => {
  useStyles(s);

  const modal = useModal<Type, Data>(id);

  const formattedActionName = _startCase(actionName);
  const formattedItem = _startCase(item);

  const getTitle = (data: Data) => {
    if (typeof title === 'function') {
      return title(data);
    }

    return title || `${formattedActionName} ${formattedItem}`;
  };

  const getContentHeading = (data: Data) => {
    if (typeof textHeader === 'function') {
      return textHeader(data);
    }

    return textHeader || `Are you sure you want to ${actionName} this ${item}?`;
  };

  const getContentText = (data: Data) => {
    if (typeof text === 'function') {
      return text(data);
    }

    return text || `Once you ${actionName} this ${item}, it can't be reversed.`;
  };

  const getExtraContent = (data: Data) => {
    if (typeof extra === 'function') {
      return extra(data);
    }

    return extra;
  };

  const getContent = (data: Data) => {
    if (typeof content === 'function') {
      return content(data);
    }

    return (
      content || (
        <>
          <Header className={s.textHeader}>{getContentHeading(data)}</Header>
          <p className={s.text}>{getContentText(data)}</p>
          {getExtraContent(data)}
        </>
      )
    );
  };

  const getSuccessMessage = (data: Data) => {
    if (typeof successMessage === 'function') {
      return successMessage(data);
    }

    return (
      successMessage ||
      `The ${item} has been successfully ${getSuccessfulActionName(actionName)}.`
    );
  };

  const handleConfirm = async () => {
    const actionResponse = await onConfirm(modal.data!);

    const isSuccessful =
      typeof actionResponse === 'boolean'
        ? actionResponse
        : actionResponse?.meta?.requestStatus === 'fulfilled';

    if (!isSuccessful) return;

    modal.close();

    raiseToast({
      toastId: `${actionName}/${item}/${id}`,
      message: getSuccessMessage(modal.data!),
      level: 'success',
      icon: successIcon || actionIcon,
      autoClose: 8000,
      dismissAll: true,
    });
  };

  if (!modal.data) return null;

  return (
    <Modal
      className={classNames(s.confirmationModal, className)}
      id={id}
      role="alertdialog"
      size="tiny"
      {...props}
    >
      <Header className={s.title}>{getTitle(modal.data)}</Header>

      <Modal.Content scrolling className={s.content}>
        {!!error && <ErrMessage deleteErr error={error} />}

        {getContent(modal.data)}
      </Modal.Content>

      <Modal.Actions className={s.actions}>
        <CancelButton
          disabled={isExecuting}
          onClick={modal.close}
          autoFocus
          dark
        >
          {cancelText}
        </CancelButton>

        <Button
          color={color}
          disabled={isExecuting}
          loading={isExecuting}
          onClick={handleConfirm}
        >
          <Icon name={actionIcon} /> {confirmText || formattedActionName}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmationModal;
