import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Image } from 'semantic-ui-react';

import type { StepsConfig } from '../tourConfig';

import s from './TourSteps.scss';

const stepStyles = {
  options: {
    width: '65rem',
  },
};

const robotEl = (
  <Image src="/img/robot_juggle.svg" className={s.robotJuggleImg} />
);

const Step1 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        The <strong>Applications</strong> view is used to collect one or more
        SBOMs together into a group representing an application or project. The
        SBOMs can either be generated from source code stored in a Git tree or
        from a container image.
      </p>
      <p>
        Application groups can then be versioned to reflect deployments. You can
        then download the entire set of SBOMs as a single bundle along with the
        associated vulnerabilities.
      </p>
    </div>
  );
};

const Step2 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        The creation of Application Groups and the association of SBOMs with a
        group is currently only handled using the anchoreCTL command line
        interface. Please see the&nbsp;
        <a
          target="_blank"
          href="https://docs.anchore.com/current/docs/sbom_management/application_groups/"
          rel="noreferrer"
        >
          Application Group
        </a>
        &nbsp;documentation for more information.
      </p>
    </div>
  );
};

export default (): StepsConfig => ({
  id: 'applications',
  steps: [
    {
      styles: stepStyles,
      content: <Step1 />,
      placement: 'center',
      target: 'body',
    },
    {
      styles: stepStyles,
      content: <Step2 />,
      placement: 'center',
      target: 'body',
    },
  ],
});
