import { compile } from 'path-to-regexp';

import type { EntityActionParams } from '@models';

const compileStateKey = <Params extends EntityActionParams | void>(
  stateKey: string,
  params: Params,
) => compile(stateKey, { validate: false })(params ?? {});

const getEntityObject = (
  state: any,
  name: string,
  params?: EntityActionParams,
) => {
  if (!name) return [state, ''];

  const stateKey = params ? compileStateKey(name, params) : name;
  const path = stateKey.split('/');
  const key = path.pop();
  if (!key) {
    throw new Error(
      'Please provide a valid "stateKey" property for the entity',
    );
  }
  let base = state;
  if (path.length) {
    path.forEach(item => {
      base = base[item];
    });
  }
  return [base, key];
};

export default getEntityObject;
