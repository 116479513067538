import { useEffect, useState } from 'react';
import type { Step } from 'react-joyride';
import Joyride from 'react-joyride';

import { setTourProps } from '@redux/actions/action-tour';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import type { TourState } from '@redux/reducers/reducer-tour';

import type { TourConfig, StepsConfig } from './tourConfig';
import tourConfigFactory from './tourConfig';

export interface AppTourProps {
  routeMask: keyof TourConfig;
}

const styleOptions = {
  options: {
    zIndex: 1001,
    textColor: 'rgba(0,0,0,.87)',
    fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
    primaryColor: '#2185d0',
  },
  buttonNext: {
    backgroundColor: '#2185d0',
    fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
    borderRadius: '.28571429rem',
    fontSize: '1rem',
    fontWeight: 700,
    outline: 'none',
    padding: '0.75rem 1.5rem',
  },
  buttonBack: {
    fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
    color: '#4183c4',
    fontSize: '1.125rem',
    fontWeight: 700,
    outline: 'none',
  },
  buttonSkip: {
    fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
    fontWeight: 700,
    fontSize: '1.25rem',
  },
  beacon: {
    top: '0.345rem',
    outline: 'none',
  },
};

// Use keyof to restrict access to valid path keys
type TourPaths = keyof NonNullable<TourState['tourProps']>;

// Main view component
const AppTour = (props: AppTourProps) => {
  // const { setTourProps } = props;

  const dispatch = useAppDispatch();
  const app = useAppSelector(state => state.app);

  // Remove context from the route mask
  let { routeMask } = props;

  // Process routeMask as a string.
  const routeMaskTmp = routeMask.split('/');
  routeMaskTmp.splice(0, 2);
  routeMask = `/${routeMaskTmp.join('/')}` as keyof TourConfig;

  const tourConfig = tourConfigFactory();

  // Get the app tour properties object
  const { tourProps } = app.tour;

  // Current path string
  const [path, setPath] = useState<string | false>(routeMask || false);

  // Configuration object for the current route
  const [config, setConfig] = useState<StepsConfig[] | false>(false);

  // Identity of the currently active step definition list
  const [stepId, setStepId] = useState<string | false>(false);

  // Step definition list
  const [steps, setSteps] = useState<Step[] | false>(false);

  // Decides if the tour should be active or paused (it's necessary to do this
  // before a state transition to get the component to reinitialize
  const [run, setRun] = useState<boolean>(false);

  // Get the tour data (if any) the corresponds to the current path from the
  // tour props object, or show the global tour if the prop for that path (`/*`)
  // is not disabled
  const tourPropsPath =
    tourProps && tourProps?.['/*']?.isDisabled === false
      ? tourProps['/*']
      : path
        ? tourProps?.[path as TourPaths]
        : false;

  // Slightly fiddly this bit. We always want to show the main tour above any of
  // route-based tours, so if the global tour is enabled, we need to ensure that
  // the route mask is set to the global tour
  routeMask =
    tourProps && tourProps?.['/*']?.isDisabled === false ? '/*' : routeMask;

  // If the route has changed then reset the path hook and other base conditions
  useEffect(() => {
    if (routeMask !== path) {
      setPath(routeMask || false);
      setConfig(false);
      setStepId(false);
      setSteps(false);
      setRun(true);
    }
  }, [routeMask, path, run]);

  // Find the correct `steps` list that corresponds to the current step ID and
  // then enable the `run` attribute on the tour component. Null the current
  // steps object if no match is found.
  useEffect(() => {
    if (config && !run && tourPropsPath) {
      const stepObj = config?.find(item => item.id === stepId);

      if (stepObj) {
        let arr = stepObj.steps;

        // Filter out any introductory steps if introductions are disabled for
        // this tour
        if (!tourPropsPath.showIntro) {
          arr = arr.filter(item => !item.data?.intro);
        }
        setSteps(arr);
        setRun(true);
      } else {
        setSteps(false);
      }
    }
  }, [tourPropsPath && !tourPropsPath.isDisabled, config]);

  // The following condition safely bootstraps the `config` and `path`
  // properties when the page is initialized (the `config` object should be
  // `false` when this happens).
  if (!config) {
    const baseConfig =
      typeof tourConfig === 'object' &&
      tourConfig[routeMask] instanceof Array &&
      tourConfig[routeMask].length
        ? tourConfig[routeMask]
        : false;

    // If the `baseConfig` was retrieved for this path, then proceed
    if (baseConfig) {
      setPath(routeMask);
      setConfig(baseConfig);
    }
  }

  // When a path and a config is available, the steps associated with the
  // current route can be determined
  if (path && config && tourProps) {
    // If the currently stored set of steps no longer correlates with the
    // calculated step object, pause the tour component and switch the step ID
    // so that it matches the new set
    if (stepId !== config[0].id) {
      setRun(false);
      setStepId(config[0].id);
    }
  }

  // If the current runtime is the browser and steps have been found that
  // correspond to the current state, render the component
  return process.env.BROWSER &&
    steps &&
    stepId &&
    tourProps &&
    tourPropsPath &&
    !tourPropsPath.isDisabled ? (
    <Joyride
      key={stepId}
      run={run}
      continuous
      styles={styleOptions}
      steps={steps}
      locale={{ last: 'Got it!' }}
      showSkipButton
      disableScrolling
      callback={data => {
        if (
          tourPropsPath &&
          (data.action === 'reset' ||
            (data.action === 'close' && data.lifecycle === 'complete'))
        ) {
          // Default props to apply when the tour is closed
          let tourPropsOnClose = {
            [routeMask]: {
              isDisabled: true,
              showIntro: true,
            },
          };

          // The tour might want to change the state of other tours that are
          // mapped to equivalent path (for example, `/reports` and
          // `/reports/new`), so this block of code will check for any
          // `overrideTourPropsOnClose` data and apply it to the tour props
          // on close
          if (config && config[0]?.data?.overrideTourPropsOnClose) {
            tourPropsOnClose = config[0].data.overrideTourPropsOnClose;
          }

          dispatch(
            setTourProps({
              ...tourProps,
              ...tourPropsOnClose,
            }),
          );
        }
      }}
    />
  ) : null;
};

export default AppTour;
